const IMAGES = {
    logo: require('../assets/images/logo.png'),
    logoGreen: require('../assets/images/logo-green.png'),
    dummy: require('../assets/images/dummy.png'),
    stores: require('../assets/images/stores.png'),
    register: require('../assets/images/register.png'),
    avatar: require('../assets/images/avatar.png'),


}

export {
    IMAGES,
}