import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import './App.css';
import PrintOptions from './pages/PrintOptions';
import Checkout from './pages/Checkout';
import MyPrintingJobs from './pages/MyPrintingJobs';
import CustomQuote from './pages/CustomQuote';
import Stores from './pages/Stores';
import StoreLogin from './pages/StoreLogin';
import Dashboard from './pages/HeadOffice/Dashboard';
import HeadOfficePrintDetails from './pages/HeadOffice/HeadOfficePrintDetails';
import HeadOfficePricing from './pages/HeadOffice/HeadOfficePricing';
import NewService from './pages/HeadOffice/AddNewService';
import HeadOfficeMyPricing from './pages/HeadOffice/HeadOfficeMyPricing';
import RegisterPrintShop from './pages/RegisterPrintShop';
import ForgotPassword from './pages/ForgotPassword';
import PrivateRoute from './firebase/PrivateRoute';
import HeadOfficeChangeMyPricing from './pages/HeadOffice/HeadOfficeChangeMyPricing';
import HeadOfficePastJobs from './pages/HeadOffice/HeadOfficePastJobs';
import ComparePrices from './pages/ComparePrices';
import VerifyPayment from './pages/VerifyPayment';
import DemoVerifyPayment from './pages/DemoVerifyPayment';
import VerifyOnlinePayment from './pages/VerifyOnlinePayment';
import Analytics from './pages/HeadOffice/Analytics';
import NotFoundPage from './pages/404';
import AuthRoute from './firebase/AuthRoute';
import AddNewService from './pages/HeadOffice/AddNewService';
import DemoPricing from './pages/DemoPricing';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/demo-signup" element={<DemoPricing />} />
        <Route path="/register-print-shop" element={<RegisterPrintShop />} />
        <Route path="/print-options" element={<PrintOptions />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/printing-jobs" element={<MyPrintingJobs />} />
        <Route path="/custom-quote" element={<CustomQuote />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/compare-prices" element={<ComparePrices />} />
        <Route path="/verify-payment" element={<VerifyPayment />} />
        <Route path="/demo-verify-payment" element={<DemoVerifyPayment />} />
        <Route path="/verify-online-payment" element={<VerifyOnlinePayment />} />

        <Route
          path="/shop-login"
          element={
            <AuthRoute>
              <StoreLogin />
            </AuthRoute>
          } />

        {/* Protect admin routes with PrivateRoute */}
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/print-details"
          element={
            <PrivateRoute>
              <HeadOfficePrintDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/pricing"
          element={
            <PrivateRoute>
              <HeadOfficePricing />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/analytics"
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/add-new-service"
          element={
            <PrivateRoute>
              <AddNewService />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/my-pricing"
          element={
            <PrivateRoute>
              <HeadOfficeMyPricing />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/change-my-pricing"
          element={
            <PrivateRoute>
              <HeadOfficeChangeMyPricing />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/closed-jobs"
          element={
            <PrivateRoute>
              <HeadOfficePastJobs />
            </PrivateRoute>
          }
        />

        {/* Catch-All Route: Keep this at the bottom */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
