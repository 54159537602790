import { useEffect, useState } from "react"
import HeadOfficeNavBar from "../../components/HeadOfficeNavBar"
import MySelector from "../../components/MySelector";
import MyMultiSelector from "../../components/MyMultiSelector";
import { ICONS } from "../../utils/icons";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";

const AddNewService = () => {
    const [user, setUser] = useState([]);
    const serviceTypeOptions = ["Paper Print", "Broachers", "Business Card", "Flyer Design"];
    const paperSizeOptions = ["A1", "A2", "A3", "A4"];
    const paperWeightOptions = ["10 gms", "20 gms", "40 gms"];
    const [serviceType, setServiceType] = useState("");
    const [paperSize, setPaperSize] = useState({});
    const [paperWeight, setPaperWeight] = useState("");
    const [description, setDescription] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState('input');
    const [errors, setErrors] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false);


    useEffect(() => {
        // Fetch user data from local storage
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            setUser(parsedUserData);
        }
    }, []); // Only run once on mount

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const removeOption = (option) => {
        setDropDownOptions((prev) => prev.filter((item) => item !== option));
    };

    const handleAdd = () => {
        if (inputValue.trim() !== "") { // Prevent adding empty values
            setDropDownOptions((prev) => [...prev, inputValue]);
            setInputValue(""); // Clear input field after adding
        }
    };

    const handleAddPricing = async () => {
        let newErrors = {};

        if (!serviceType) newErrors.serviceType = true;
        if (!paperSize.length > 0) newErrors.paperSize = true;
        if (!paperWeight) newErrors.paperWeight = true;
        if (!description) newErrors.description = true;

        setErrors(newErrors); // Update error state
        console.log(errors);

        if (Object.keys(newErrors).length > 0) return; // Stop if there are errors

        try {
            setIsSubmiting(true);
            await addDoc(collection(db, "services"), {
                serviceType,
                paperSize,
                paperWeight,
                description,
                createdAt: new Date(),
            });

            setIsSubmiting(false);
        } catch (error) {
            console.error("Error adding document: ", error);
            setIsSubmiting(false);
        }
    };


    return (
        <div className='bg-white'>
            <HeadOfficeNavBar user={user} />
            <div className='px-4 pt-6 md:px-10 lg:px-40 pb-5 min-h-screen flex flex-col'>
                <div className="pt-16 md:pt-24">
                    <h2 className='text-black font-bold font-20 md:text-[28px] my-5'>Add New Service</h2>
                </div>
                <div className='justify-items-center'>
                    <div className='add-prices-container rounded-3xl px-5 py-5 mt-5 w-full bg-white'>
                        <div className="flex items-center justify-between">
                            <h2 className='text-black font-semibold font-16 md:text-[20px] text-textBlack'>Create New Service</h2>
                            <button
                                onClick={handleOpenModal}
                                className="text-white px-5 py-3 rounded-xl bg-primary font-semibold font-16 self-center md:self-end md:mt-0 mt-3"
                            >
                                Add new Variable
                            </button>
                        </div>
                        <div className="mt-5 w-full lg:w-[60%] ">
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Service  Type</p>
                                <MySelector
                                    options={serviceTypeOptions}
                                    placeHolder="Select Service Type"
                                    handleSaveState={(val) => setServiceType(val)}
                                    error={errors.serviceType}
                                />
                            </div>
                            <div className='flex flex-1 mt-3 gap-3'>
                                <div className='flex-[0.5]'>
                                    <p className='text-textBlack font-medium font-14 mb-3'>Paper Size</p>
                                    <MyMultiSelector
                                        options={paperSizeOptions}
                                        placeHolder="Select Paper Size"
                                        handleSaveState={(val) => setPaperSize(val)}
                                        error={errors.paperSize}
                                    />
                                </div>
                                <div className='flex-[0.5]'>
                                    <p className='text-textBlack font-medium font-14 mb-3'>Paper Weight</p>
                                    <MySelector
                                        options={paperWeightOptions}
                                        placeHolder="Select Paper Weight"
                                        handleSaveState={(val) => setPaperWeight(val)}
                                        error={errors.paperWeight}
                                    />
                                </div>
                            </div>
                            <div className='flex-1 mt-3'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Description</p>
                                <textarea
                                    name="description"
                                    id="description"
                                    type="text"
                                    value={description}
                                    rows={4}
                                    placeHolder="About the service"
                                    onChange={(event) => setDescription(event.target.value)}
                                    className={`text-textBlack border ${errors.description ? 'border-danger' : 'border-borderColor'} placeholder-lightGrey px-4 py-2 rounded-xl outline-none font-16 w-full`}
                                >
                                </textarea>
                                <p className='text-darkGrey font-normal font-14'>Kindly explain how you would like the new service to be used by your customers. Include the exact per unit pricing for your new service as well, and we will implement it shortly.</p>
                            </div>
                        </div>

                        <div className="mt-8">
                            <h2 className='text-black font-semibold font-16 md:text-[20px] text-textBlack'>Added Variables</h2>
                            <div className="space-y-4">
                                <div
                                    className="flex items-center justify-between border-b rounded-lg py-4 border-b-borderColor"
                                >
                                    <div className="flex gap-6 md:gap-16">
                                        <h3 className="text-lightGrey font-15 lg:text-[16px] font-medium">Printing Calendars</h3>
                                        <p className="text-lightGrey font-15 lg:text-[16px] font-normal">A4, 2gms, color print</p>
                                    </div>
                                    <div className="flex space-x-3">
                                        <button>
                                            <img
                                                alt="Edit"
                                                src={ICONS.edit}
                                                className={`h-6 md:h-5`}
                                            />
                                        </button>
                                        <button>
                                            <img
                                                alt="Delete"
                                                src={ICONS.delete}
                                                className={`h-6 md:h-5`}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 mb-5 flex items-center justify-center lg:block">
                            <button
                                onClick={handleAddPricing}
                                className="text-white px-8 py-3 rounded-xl bg-primary font-semibold font-16 self-center md:self-end md:mt-0 mt-3"
                            >
                                Add Pricing
                            </button>
                        </div>
                    </div>
                </div>
                {isSubmiting && (
                    // Display loading spinner/message while fetching jobs
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                        <div className="bg-white p-4 rounded-md shadow-lg">
                            <p className="text-lg font-semibold">Sending...</p>
                        </div>
                    </div>
                )
                }
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 lg:px-20" style={{ zIndex: 999 }}>
                        <div className="bg-white p-5 md:p-6 rounded-3xl my-16 max-h-[800px] overflow-auto w-[90%] md:w-[70%] lg:w-[60%]">
                            <div className="mb-5">
                                <h2 className="font-18 md:text-xl font-bold mb-1 text-textBlack">Add new Variable</h2>
                                <p className="text-lightGrey font-14 lg:text-[16px] font-normal">Please enter the detail which kind of variable you want to add.</p>
                            </div>
                            <div className="flex gap-8">
                                <button
                                    className="my-toggle-button gap-2"
                                    onClick={() => setSelected("input")}
                                >
                                    <div
                                        className={`radio-circle ${selected === "input" ? "my-selected" : ""}`}
                                    />
                                    Input text field
                                </button>
                                <button
                                    className="my-toggle-button gap-2"
                                    onClick={() => setSelected("option")}
                                >
                                    <div
                                        className={`radio-circle ${selected === "option" ? "my-selected" : ""}`}
                                    />
                                    Select option field
                                </button>
                            </div>
                            <div className="mt-8">
                                <div className='flex-1'>
                                    <p className='text-textBlack font-medium font-14 mb-3'>Enter field Name</p>
                                    <input
                                        name="fieldName"
                                        id="fieldName"
                                        type="text"
                                        value={fieldName}
                                        placeHolder="Type here..."
                                        onChange={(event) => setFieldName(event.target.value)}
                                        className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none font-16 w-full`}
                                    >
                                    </input>
                                </div>
                                {
                                    selected === "option" && (
                                        <div className="mt-3">
                                            <div className='flex-1'>
                                                <p className='text-textBlack font-medium font-14 mb-3'>Drop down options</p>
                                                <div className="border border-borderColor rounded-xl flex px-4">
                                                    <input
                                                        name="fieldName"
                                                        id="fieldName"
                                                        type="text"
                                                        placeHolder="Type here..."
                                                        value={inputValue}
                                                        onChange={(event) => setInputValue(event.target.value)}
                                                        className={`text-textBlack placeholder-lightGrey mr-3 py-2 outline-none rounded-xl font-16 flex-1`}
                                                    />
                                                    <button
                                                        onClick={handleAdd}
                                                    >
                                                        <p className="text-primary font-14 font-semibold">Add</p>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                {dropDownOptions.length > 0 && (
                                                    dropDownOptions.map((option, index) => (
                                                        <div className="flex dropDownOptions-item-box">
                                                            <span key={index} className="dropDownOptions-selected-item">
                                                                {option}
                                                            </span>
                                                            <img
                                                                src={ICONS.close}
                                                                className="dropDownOptions-remove-item w-2 h-2"
                                                                alt="Icon"
                                                                onClick={() => removeOption(option)}
                                                            />
                                                        </div>
                                                    ))
                                                )
                                                }
                                            </div>

                                        </div>
                                    )
                                }
                                <div
                                    className="flex items-center justify-end gap-4 mt-5">
                                    <button
                                        onClick={handleCloseModal}
                                        className="text-primary px-12 py-3 rounded-xl border border-primary font-semibold font-14 self-center"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => { }}
                                        className="text-white px-14 py-3 rounded-xl bg-primary border border-primary font-semibold font-14 self-center"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddNewService;