import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { COLORS } from "../utils/theme";
import { ICONS } from "../utils/icons";
import Footer from "../components/Footer";


const Checkout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUploading, setIsUploading] = useState(false);
    const { documentId, fileList, totalPrice, emailAddress, subAccountCode, selectedShop } = location.state || {};

    const [files, setFiles] = useState(fileList || []);
    const [price, setPrice] = useState(totalPrice || 0);



    const [isSubmited, setIsSubmited] = useState('');

    const handleReturn = () => {
        // Navigate to the next page with the document ID in the state
        navigate('/', { state: { printRequestId: documentId } });
    }

    const handlePayInStoreClick = async () => {
        if (files.length > 0) {
            if (documentId) {
                setIsUploading(true);
                try {
                    // Reference to the document in Firestore
                    const docRef = doc(db, 'print_requests', documentId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {

                        await updateDoc(docRef, {
                            files: fileList,
                            totalPrice: totalPrice,
                            status: 'Received'
                        });
                        setIsUploading(false);
                        setIsSubmited('store')

                    } else {
                        setIsUploading(false);
                        console.error('No such document!');
                    }
                } catch (error) {
                    setIsUploading(false);
                    console.error('Error fetching document:', error);
                }
            } else {
                console.error('document ID not found:', documentId);
            }
        }

    }


    const handlePayOnlineClick = async () => {
        try {
            if (files.length > 0) {
                if (documentId) {
                    console.log("Sub account:", subAccountCode);
                    // Reference to the document in Firestore
                    const docRef = doc(db, 'print_requests', documentId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {

                        await updateDoc(docRef, {
                            files: fileList,
                            totalPrice: totalPrice,
                        });

                    } else {
                        console.error('No such document!');
                    }
                    const response = await fetch('https://api.paystack.co/transaction/initialize', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer sk_live_175c4cb1de422b556b365c7c33554ed5349d5d89`
                        },
                        body: JSON.stringify({
                            email: emailAddress,     // Customer email
                            amount: totalPrice * 100, // Amount in Kobo (ZAR * 100)
                            subaccount: subAccountCode,
                            currency: 'ZAR',
                            callback_url: `https://signetprint.co.za/verify-online-payment?printRequestId=${documentId}`,
                        })
                    });

                    const data = await response.json();
                    if (data.status) {
                        // Redirect to Paystack payment link
                        window.location.href = data.data.authorization_url;
                    } else {
                        console.error('Transaction initialization failed:', data.message);
                    }
                }
            }
        } catch (error) {
            console.error('Error initializing transaction:', error);
        }

    };

    const handleEditClick = (index) => {
        // Navigate to the next page with the document ID in the state
        navigate('/', { state: { printRequestId: documentId, currentIndex: index } });
    }


    const handleDeleteFile = async (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        const updatedPrice = updatedFiles.reduce((acc, file) => acc + Number(file.price), 0);

        setFiles(updatedFiles);
        setPrice(updatedPrice);

        if (documentId) {
            try {
                const docRef = doc(db, 'print_requests', documentId);
                await updateDoc(docRef, { files: updatedFiles, totalPrice: updatedPrice });
            } catch (error) {
                console.error("Error updating document:", error);
            }
        }
    };



    return (
        <div className="bg-white min-h-screen">
            <div className="relative isolate px-4 pt-8 md:px-10 lg:px-40">
                <div className='mb-20'>
                    <NavBar />
                </div>
                <div className="mb-10 mt-16">
                    <button onClick={() => handleReturn()}>
                        <img
                            src={ICONS.back}
                            alt="Back"
                            className="w-8 h-8"
                        />
                    </button>
                    <h2 className="text-textBlack font-28 font-bold mt-5">
                        Print Details
                    </h2>
                    <h2 className="text-darkGrey font-20 font-medium">
                        {selectedShop}
                    </h2>
                </div>
            </div>
            <div className="px-4 md:px-10 lg:px-40">
                <div className="bg-white upload-files-main mt-10 flex flex-col pb-8 py-5 px-4 md:px-8 overflow-x-auto mb-16">
                    <table className="w-full border-collapse">
                        {/* Table Head */}
                        <thead>
                            <tr className="text-left">
                                <th className="py-3 px-4 font-semibold text-textBlack">Name</th>
                                <th className="py-3 px-4 font-semibold text-textBlack">Description</th>
                                <th className="py-3 px-4 font-semibold text-textBlack">Quantity</th>
                                <th className="py-3 px-4 font-semibold text-textBlack">Price</th>
                                <th className="py-3 px-4 font-semibold text-textBlack">Action</th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody>
                            {
                                files.map((file, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="py-3 px-4">
                                            <div className='flex items-center'>
                                                <img alt="PDF" src={ICONS.pdf} className="h-4 pr-2" />
                                                <p className='text-darkGrey font-14 font-normal'>{file.fileName}</p>
                                            </div>
                                        </td>
                                        <td className="py-3 px-4">
                                            <p className='text-darkGrey font-14 font-regular'>
                                                {Object.entries(file.printOptions)
                                                    .filter(([key, value]) => value !== "" && value !== null && value !== undefined) // Remove empty values
                                                    .map(([key, value]) => {
                                                        if (key === "quantity") return `X${value}`; // Show "Binding" if it's "Yes"
                                                        if (key === "binding" && value === "Yes") return "Binding"; // Show "Binding" if it's "Yes"
                                                        if (key === "doubleSided" && value === "Yes") return "Double Sided"; // Show "Double Binding" if true
                                                        return value; // Default case
                                                    })
                                                    .join(", ")}

                                            </p>
                                        </td>
                                        <td className="py-3 px-4">
                                            <p className='text-darkGrey font-14 font-regular'>
                                                {file.printOptions.quantity} Unit
                                            </p>
                                        </td>
                                        <td className="py-3 px-4">
                                            <p className='text-textBlack font-14 font-bold'>
                                                R {file.price}
                                            </p>
                                        </td>
                                        <td className="py-3 px-4">
                                            <div className='flex items-center'>
                                                <button
                                                    onClick={() => handleEditClick(index)}
                                                >
                                                    <img alt="Edit" src={ICONS.edit} className='h-6 pl-1 mr-2' />
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteFile(index)}
                                                >
                                                    <img alt="Delete" src={ICONS.delete} className='h-6' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>

                    <div className='flex flex-col items-end mt-8'>
                        <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font mb-3">
                            Total Price: R {price}
                        </h2>
                        <div className="flex items-center gap-2 mt-4">
                            <button
                                onClick={handlePayInStoreClick}
                                className="text-primary px-4 py-2 rounded-xl border border-primary font-semibold font-14"
                            >
                                Pay in Store
                            </button>
                            <button
                                onClick={handlePayOnlineClick}
                                className="text-white px-4 py-2 rounded-xl border border-primary bg-primary font-semibold font-14"
                            >
                                Pay Online
                            </button>
                        </div>
                    </div>

                    <div className='bg-info flex items-center justify-center mt-8 px-5 py-5 rounded-md mx-4 md:mx-12 lg:mx-36'>
                        <img
                            src={ICONS.info}
                            alt="Icon"
                            className="w-5 h-5 mr-2" />
                        <p className='text-orange font-14 font-regular'>
                            When you choose to pay in-store, please note that your prints won’t be prepared until you arrive and complete payment.
                        </p>
                    </div>
                </div>
            </div>
            {isUploading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50" style={{ marginTop: 0 }}>
                    <div className="bg-white p-4 rounded-md shadow-lg">
                        {/* Circular Progress Spinner */}
                        <div className="spinner"></div>
                    </div>
                </div>
            )}

            {isSubmited && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 p-4">
                    <div className="bg-white p-4 rounded-md shadow-lg w-full sm:max-w-md lg:max-w-5xl text-center mx-auto">
                        <div className="cross-icon flex justify-end">
                            <span onClick={() => setIsSubmited('')} className="cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={COLORS.black} className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                        {isSubmited === 'store' ? (
                            <p className="text-lg font-medium pb-5">
                                Thank you for your order! <br />
                                We've received your PDF, email, and desired pickup date. Please remember to bring your payment with you when you come to pick up your order.
                            </p>
                        ) : (
                            <p className="text-lg font-medium pb-5">
                                Thank you for your order! <br />
                                We've received your PDF, email, and desired pickup date. You may come collect your order on the given pickup date.
                            </p>
                        )}

                        <Link to={'/'} className="text-white px-4 py-2 rounded-xl border border-primary bg-primary font-semibold font-14"
                        >
                            Return to Home
                        </Link>
                    </div>
                </div>

            )}
            <Footer />
        </div>
    )
}

export default Checkout;