import React from 'react';
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    return (
        <section class="bg-gray-900 min-h-[100vh]">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 content-center min-h-[100vh]">
                <div class="mx-auto max-w-screen-sm text-center">
                    <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-white">404</h1>
                    <p class="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Page not found currently</p>
                    <p class="mb-4 text-lg font-light text-gray-300">Sorry, we can't find such page.</p>

                    <Link
                        to="/"
                        class="text-white text-center bg-primary hover:bg-oceanBlue focus:outline-none focus:ring-1 focus:bg-oceanBlue font-medium rounded-full text-sm px-5 py-2.5 mb-2"
                    >
                        Back To Home
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default NotFoundPage