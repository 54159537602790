import { useEffect, useState } from 'react';
import HeadOfficeNavBar from '../../components/HeadOfficeNavBar'
import { getDocs, query, collection, where } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../utils/icons';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Dot } from "recharts";
import MySelector from '../../components/MySelector';


const lineData = [
    { month: "JAN", mainValue: 12000, secondaryValue: 22000 },
    { month: "FEB", mainValue: 19000, secondaryValue: 14000 },
    { month: "MAR", mainValue: 14000, secondaryValue: 33000 },
    { month: "APR", mainValue: 35000, secondaryValue: 25000 },
    { month: "MAY", mainValue: 28000, secondaryValue: 15000 },
    { month: "JUN", mainValue: 47000, secondaryValue: 21000 },
    { month: "JUL", mainValue: 27000, secondaryValue: 12000 },
];

const customerData = [
    { name: "Jan", loyal: 40, new: 25, unique: 38 },
    { name: "Feb", loyal: 42, new: 22, unique: 40 },
    { name: "Mar", loyal: 38, new: 20, unique: 35 },
    { name: "Apr", loyal: 35, new: 18, unique: 32 },
    { name: "May", loyal: 30, new: 22, unique: 28 },
    { name: "Jun", loyal: 37, new: 30, unique: 36 },
    { name: "Jul", loyal: 45, new: 50, unique: 43 }, // Highlighted month
    { name: "Aug", loyal: 42, new: 47, unique: 40 },
    { name: "Sep", loyal: 38, new: 42, unique: 36 },
    { name: "Oct", loyal: 35, new: 38, unique: 32 },
    { name: "Nov", loyal: 32, new: 30, unique: 28 },
    { name: "Dec", loyal: 30, new: 25, unique: 26 }
];

const CustomDot = (props) => {
    const { cx, cy, stroke, value } = props;
    return (
        <Dot cx={cx} cy={cy} r={5} fill={stroke} stroke="white" strokeWidth={2} />
    );
};


const Analytics = () => {
    const [user, setUser] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [completedJobs, setCompletedJobs] = useState(0);
    const [ongoingJobs, setOngoingJobs] = useState(0);
    const navigate = useNavigate();

    const overviewTotalJobs = 59;
    const overviewCompletedJobs = 50;
    const overviewOngoingJobs = 9;


    useEffect(() => {
        // Fetch user data from local storage
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            setUser(parsedUserData);
        }
    }, []); // Only run once on mount

    useEffect(() => {
        // Fetch print requests based on user ID
        const fetchPrintRequests = async () => {
            if (user?.id) {
                try {
                    // Reference to the print_requests collection
                    const q = query(
                        collection(db, 'print_requests'),
                        where('storeID', '==', user.id)
                    );
                    const querySnapshot = await getDocs(q);

                    // Map the query results to an array of job objects
                    const fetchedJobs = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    setJobs(fetchedJobs);
                } catch (error) {
                    console.error('Error fetching print requests:', error);
                }
            }
        };

        fetchPrintRequests();
    }, [user]); // Runs when `user` changes

    useEffect(() => {
        if (jobs.length > 0) {
            // Calculate total sales (sum of all totalPrice values)
            const total = jobs.reduce((sum, job) => sum + (job.totalPrice || 0), 0);
            setTotalSales(total);

            // Count completed jobs
            const completed = jobs.filter(job => job.status === 'Completed').length;
            setCompletedJobs(completed);

            // Count ongoing jobs (where status is 'Received')
            const ongoing = jobs.filter(job => job.status === 'Received').length;
            setOngoingJobs(ongoing);
        }
    }, [jobs]);


    return (
        <div className='bg-white min-h-screen'>
            <HeadOfficeNavBar user={user} />
            <div className='px-4 pt-6 md:px-10 lg:px-40 pb-5 min-h-screen flex flex-col'>
                <div className="py-16 md:py-24 ">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-5 lg:gap-6 mt-10 gap-y-4">
                        <div className='border border-[#51E156] analytics-items rounded-2xl flex flex-col px-4 py-4'>
                            <div
                                className='flex items-center justify-between'
                            >
                                <p
                                    className='font-normal text-[15px] text-secondary'
                                >
                                    Total Sales
                                </p>
                                <img
                                    src={ICONS.totalSales}
                                    className='w-8 h-8'
                                    alt='TotalSales'
                                />
                            </div>
                            <p
                                className='font-bold font-20 text-secondary'
                            >
                                R{totalSales}
                            </p>
                            <p
                                className='font-normal font-16 text-darkGrey mt-4'
                            >
                                +3% from last month
                            </p>
                        </div>
                        <div className='border border-[#5B54B5] analytics-items rounded-2xl flex flex-col px-4 py-4'>
                            <div
                                className='flex items-center justify-between'
                            >
                                <p
                                    className='font-normal text-[15px] text-secondary'
                                >
                                    Completed Jobs
                                </p>
                                <img
                                    src={ICONS.completedJobs}
                                    className='w-8 h-8'
                                    alt='CompletedJobs'
                                />
                            </div>
                            <p
                                className='font-bold font-20 text-secondary'
                            >
                                {completedJobs}
                            </p>
                            <p
                                className='font-normal font-16 text-darkGrey mt-4'
                            >
                                +2% from last month
                            </p>
                        </div>
                        <div className='border border-[#BF58AC] analytics-items rounded-2xl flex flex-col px-4 py-4'>
                            <div
                                className='flex items-center justify-between'
                            >
                                <p
                                    className='font-normal text-[15px] text-secondary'
                                >
                                    Ongoing Jobs
                                </p>
                                <img
                                    src={ICONS.onGoingJobs}
                                    className='w-8 h-8'
                                    alt='OngoingJobs'
                                />
                            </div>
                            <p
                                className='font-bold font-20 text-secondary'
                            >
                                {ongoingJobs}
                            </p>
                            <p
                                className='font-normal font-16 text-darkGrey mt-4'
                            >
                                +3% from last month
                            </p>
                        </div>
                        <div className='border border-[#DCC647] analytics-items rounded-2xl flex flex-col px-4 py-4'>
                            <div
                                className='flex items-center justify-between'
                            >
                                <p
                                    className='font-normal text-[15px] text-secondary'
                                >
                                    Machinery in Use
                                </p>
                                <img
                                    src={ICONS.machineryInUse}
                                    className='w-8 h-8'
                                    alt='MachineryInUse'
                                />
                            </div>
                            <p
                                className='font-bold font-20 text-secondary'
                            >
                                4
                            </p>
                            <p
                                className='font-normal font-16 text-darkGrey mt-4'
                            >
                                +3% from last month
                            </p>
                        </div>
                    </div>

                    {/* Charts */}

                    <div
                        className='flex lg:items-center mt-8 gap-3 flex-col lg:flex-row'
                    >
                        <div className='flex-1'>
                            <div className="bg-white overview-chart rounded-2xl p-4">
                                <div
                                    className='flex items-center justify-between'
                                >
                                    <h2 className="text-secondary font-18 font-bold mb-3">Overview</h2>
                                    <div
                                        className=''
                                    >
                                        <MySelector
                                            options={["This Month", "This Week"]}
                                            placeHolder="This Month"
                                            handleSaveState={(val) => { }}
                                        />
                                    </div>
                                </div>
                                <ResponsiveContainer width="100%" height={250}>
                                    <LineChart data={lineData} margin={{ top: 20 }}>
                                        <XAxis dataKey="month" tick={false} axisLine={false} />

                                        <YAxis
                                            domain={[10000, 50000]}
                                            tick={false} // Hide tick values
                                            axisLine={false} // Hide Y-axis line
                                            tickFormatter={(value) => `${value / 1000}K`} // Formatting still applied for tooltips
                                        />

                                        <Tooltip
                                            formatter={(value) => `${value / 1000}K`} // Tooltip shows "K" values
                                        />

                                        {[10000, 20000, 30000, 40000, 50000].map((y) => (
                                            <ReferenceLine
                                                key={y}
                                                y={y}
                                                stroke="#ECE9F1"
                                                strokeWidth={1}
                                                label={{
                                                    position: "left",
                                                    value: `${y / 1000}K`, // Formatting in ReferenceLine labels
                                                    style: { fill: "#6B7280", fontSize: 12 },
                                                }}
                                            />
                                        ))}

                                        {lineData.map((item, index) => (
                                            <ReferenceLine
                                                key={index}
                                                x={item.month}
                                                stroke="transparent" // No visible line
                                                label={{
                                                    position: "bottom",
                                                    value: item.month, // Show month labels
                                                    style: { fill: "#6B7280", fontSize: 12, fontWeight: "500" },
                                                }}
                                            />
                                        ))}
                                        <Line
                                            type="monotone"
                                            dataKey="secondaryValue"
                                            stroke="#ECE9F1"
                                            strokeWidth={3}
                                            dot={false}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="mainValue"
                                            stroke="#05B49B"
                                            strokeWidth={4}
                                            dot={{ fill: "#05B49B", r: 5 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className='flex-1 lg:flex-[0.5]'>
                            <div className="bg-white rounded-2xl p-4 overview-chart w-full lg:max-w-md mx-auto min-h-[320px]">
                                {/* Header */}
                                <h2 className="text-secondary font-bold text-lg mb-2">Jobs Overview</h2>

                                {/* Total Count */}
                                <div className="flex items-center space-x-2 mt-8">
                                    <span className="text-4xl font-bold text-secondary">{overviewTotalJobs}</span>
                                    <span className="text-darkGrey text-lg">Total</span>
                                </div>

                                {/* Progress Bar */}
                                <div className="mt-8 relative w-full bg-gray-200 rounded-full h-3 overflow-hidden">
                                    <div
                                        className="bg-primary h-3 absolute left-0 top-0"
                                        style={{ width: `${(overviewCompletedJobs / overviewTotalJobs) * 100}%` }}
                                    />
                                    <div
                                        className="bg-[#7459D933] h-3 absolute top-0"
                                        style={{
                                            width: `${(overviewOngoingJobs / overviewTotalJobs) * 100}%`,
                                            left: `${(overviewCompletedJobs / overviewTotalJobs) * 100}%`
                                        }}
                                    />
                                </div>
                                {/* Legend */}
                                <div className="flex justify-between items-center mt-12">
                                    <div className="flex items-center">
                                        <span className="w-4 h-1.5 bg-primary rounded-full mr-2"></span>
                                        <span className="text-darkGrey text-sm">Completed</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="w-4 h-1.5 bg-[#7459D933] rounded-full mr-2"></span>
                                        <span className="text-darkGrey text-sm">Ongoing</span>
                                    </div>
                                </div>

                                {/* Numbers */}
                                <div className="flex justify-between mt-2">
                                    <span className="text-lg font-bold text-secondary">{overviewCompletedJobs}</span>
                                    <span className="text-lg font-bold text-secondary">{overviewOngoingJobs}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='w-[100%] lg:w-[40%] mt-8'>
                        <div className="bg-white overview-chart rounded-2xl p-4">
                            <h2 className="text-gray-900 font-bold text-xl mb-2">Customers</h2>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={customerData} margin={{ top: 20, right: 20, left: 0, bottom: 40 }}>
                                    {/* X and Y Axis */}
                                    <XAxis
                                        dataKey="name"
                                        stroke="#666"
                                    />

                                    <YAxis
                                        stroke="#666"
                                    />

                                    {/* Tooltip */}
                                    <Tooltip />

                                    {/* Reference Line for July */}
                                    <ReferenceLine x="Jul" stroke="red" strokeDasharray="4 4" />

                                    {[0, 15, 30, 45, 60].map((y) => (
                                        <ReferenceLine
                                            key={y}
                                            y={y}
                                            stroke="#ECE9F1"
                                            strokeWidth={1}

                                        />
                                    ))}

                                    {/* Line Charts */}
                                    <Line type="monotone" dataKey="loyal" stroke="#A700FF" strokeWidth={3} dot={<CustomDot />} />
                                    <Line type="monotone" dataKey="new" stroke="#EF4444" strokeWidth={3} dot={<CustomDot />} />
                                    <Line type="monotone" dataKey="unique" stroke="#3CD856" strokeWidth={3} dot={<CustomDot />} />

                                    {/* Legend */}
                                    <Legend verticalAlign="bottom" align="center" iconType="circle" wrapperStyle={{ bottom: 0 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default Analytics;