const ICONS = {
    menu: require('../assets/icons/menu.png'),
    documentUpload: require('../assets/icons/document-upload.png'),
    pdf: require('../assets/icons/pdf.png'),
    edit: require('../assets/icons/edit-outline.png'),
    delete: require('../assets/icons/delete-outline.png'),
    gps: require('../assets/icons/gps.png'),
    arrowDown: require('../assets/icons/arrow-down.png'),
    search: require('../assets/icons/search.png'),
    searchPrimary: require('../assets/icons/search-primary.png'),
    upload: require('../assets/icons/upload.png'),
    files: require('../assets/icons/files.png'),
    document: require('../assets/icons/document.png'),
    ratingStart: require('../assets/icons/star.png'),
    facebook: require('../assets/icons/facebook-black.png'),
    twitter: require('../assets/icons/twitter-black.png'),
    vimeo: require('../assets/icons/vimeo-black.png'),
    youtube: require('../assets/icons/youtube-black.png'),
    checkCircle: require('../assets/icons/check-circle.png'),
    back: require('../assets/icons/ep_back.png'),
    info: require('../assets/icons/weui_info-outlined.png'),
    location: require('../assets/icons/stores-location.png'),
    phone: require('../assets/icons/phone.png'),
    cloudAdd: require('../assets/icons/cloud-add.png'),
    close: require('../assets/icons/close.png'),
    bell: require('../assets/icons/bel.png'),
    download: require('../assets/icons/download.png'),
    infoBlue: require('../assets/icons/info-blue.png'),
    totalSales: require('../assets/icons/total-sales.png'),
    completedJobs: require('../assets/icons/completed-jobs.png'),
    onGoingJobs: require('../assets/icons/ongoing-jobs.png'),
    machineryInUse: require('../assets/icons/machinery-in-use.png'),


}


export {
    ICONS,
}