import React, { useEffect, useState } from 'react';
import { IMAGES } from '../utils/images';
import { auth, db } from '../firebase/firebaseConfig'; // import your firebase setup
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Eye, EyeOff } from "lucide-react";
import { signOut } from 'firebase/auth';


const RegisterPrintShop = () => {
    const [formData, setFormData] = useState({
        storeName: '',
        address: '',
        phoneNumber: '',
        email: '',
        bankName: '',
        accountNumbr: '',
        password: '',
    });
    const [isRegistering, setIsRegistering] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [banks, setBanks] = useState([]);
    const { paymentSuccessful } = location.state || {};
    const payStackLiveKey = "sk_live_175c4cb1de422b556b365c7c33554ed5349d5d89";
    const payStackTestKey = "sk_test_6387dfc884da169e373d841492096eaa2ed84b2f";


    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const response = await axios.get('https://api.paystack.co/bank', {
                    params: {
                        country: 'south africa'
                    },
                    headers: {
                        Authorization: `Bearer ${payStackLiveKey}`  // Replace with your Paystack secret key
                    }
                });

                setBanks(response.data.data);
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
        };

        fetchBanks();
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsRegistering(true);

        // Check if store name already exists
        try {
            const storeQuery = query(collection(db, 'users'), where('storeName', '==', formData.storeName));
            const storeSnapshot = await getDocs(storeQuery);

            if (!storeSnapshot.empty) {
                alert('Store name already exists');
                return;
            }

            // Step 1: Create Subaccount via Paystack
            const subaccountData = {
                business_name: formData.storeName,
                settlement_bank: formData.bankName,
                account_number: formData.accountNumbr,
                percentage_charge: 0
            };

            const subaccountResponse = await axios.post('https://api.paystack.co/subaccount', subaccountData, {
                headers: {
                    Authorization: `Bearer ${payStackLiveKey}`,  // Replace with your Paystack secret key
                    'Content-Type': 'application/json'
                }
            });

            if (subaccountResponse.data.status !== true) {
                alert('Bank Account not created');
                return;
            }

            const subaccountCode = subaccountResponse.data.data.subaccount_code;

            const userCredential = await createUserWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            );
            const user = userCredential.user;


            const defaultBuildingPlanTable = [
                {
                    type: "B/W",
                    sizes: [
                        { size: "A2", price: 40 },
                        { size: "A1", price: 50 },
                        { size: "A0", price: 65 }
                    ]
                },
                {
                    type: "Color",
                    sizes: [
                        { size: "A2", price: 55 },
                        { size: "A1", price: 70 },
                        { size: "A0", price: 110 }
                    ]
                }
            ];
            const defaultPricingTable = [
                {
                    "id": 1,
                    "paperSize": "A4",
                    "color": "B/W",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 2.5 },
                        { "pages": "11-50 pgs", "price": 2 },
                        { "pages": "51-250 pgs", "price": 1.8 },
                        { "pages": "251-500 pgs", "price": 1.5 },
                        { "pages": "501-1000 pgs", "price": 1.2 },
                        { "pages": "1000+ pgs", "price": 1 }
                    ]
                },
                {
                    "id": 2,
                    "paperSize": "A4",
                    "color": "Color",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 10 },
                        { "pages": "11-50 pgs", "price": 8 },
                        { "pages": "51-250 pgs", "price": 6.5 },
                        { "pages": "251-500 pgs", "price": 6.5 },
                        { "pages": "501-1000 pgs", "price": 6.5 },
                        { "pages": "1000+ pgs", "price": 6.5 }
                    ]
                },
                {
                    "id": 3,
                    "paperSize": "A3",
                    "color": "B/W",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 5 },
                        { "pages": "11-50 pgs", "price": 4 },
                        { "pages": "51-250 pgs", "price": 3 },
                        { "pages": "251-500 pgs", "price": 2.5 },
                        { "pages": "501-1000 pgs", "price": 2 },
                        { "pages": "1000+ pgs", "price": 1.8 }
                    ]
                },
                {
                    "id": 4,
                    "paperSize": "A3",
                    "color": "Color",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 20 },
                        { "pages": "11-50 pgs", "price": 18 },
                        { "pages": "51-250 pgs", "price": 15 },
                        { "pages": "251-500 pgs", "price": 13 },
                        { "pages": "501-1000 pgs", "price": 12 },
                        { "pages": "1000+ pgs", "price": 10 }
                    ]
                },
                {
                    "id": 5,
                    "paperSize": "A2",
                    "color": "B/W",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 55 },
                        { "pages": "11-50 pgs", "price": 55 },
                        { "pages": "51-250 pgs", "price": 55 },
                        { "pages": "251-500 pgs", "price": 55 },
                        { "pages": "501-1000 pgs", "price": 55 },
                        { "pages": "1000+ pgs", "price": 55 }
                    ]
                },
                {
                    "id": 6,
                    "paperSize": "A2",
                    "color": "Color",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 80 },
                        { "pages": "11-50 pgs", "price": 80 },
                        { "pages": "51-250 pgs", "price": 80 },
                        { "pages": "251-500 pgs", "price": 80 },
                        { "pages": "501-1000 pgs", "price": 80 },
                        { "pages": "1000+ pgs", "price": 80 }
                    ]
                },
                {
                    "id": 7,
                    "paperSize": "A1",
                    "color": "B/W",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 68 },
                        { "pages": "11-50 pgs", "price": 68 },
                        { "pages": "51-250 pgs", "price": 68 },
                        { "pages": "251-500 pgs", "price": 68 },
                        { "pages": "501-1000 pgs", "price": 68 },
                        { "pages": "1000+ pgs", "price": 68 }
                    ]
                },
                {
                    "id": 8,
                    "paperSize": "A1",
                    "color": "Color",
                    "priceRanges": [
                        { "pages": "1-10 pgs", "price": 135 },
                        { "pages": "11-50 pgs", "price": 135 },
                        { "pages": "51-250 pgs", "price": 135 },
                        { "pages": "251-500 pgs", "price": 135 },
                        { "pages": "501-1000 pgs", "price": 135 },
                        { "pages": "1000+ pgs", "price": 135 }
                    ]
                }
            ];


            // Save additional user data in Firestore
            await setDoc(doc(db, 'users', user.uid), {
                id: user.uid,
                storeName: formData.storeName,
                address: formData.address,
                phoneNumber: formData.phoneNumber,
                email: formData.email,
                bankName: formData.bankName,
                accountNumbr: formData.accountNumbr,
                subaccountCode: subaccountCode,
                pricing: defaultPricingTable,
                buildingPlanPricing: defaultBuildingPlanTable,
            });
            await signOut(auth);
            localStorage.removeItem('user');
            navigate('/shop-login');
        } catch (error) {
            console.error('Error registering user:', error);
            alert(error.message);
        } finally {
            setIsRegistering(false);
        }
    };

    const handleBack = () => {
        navigate('/'); // Go back to the previous page
    };

    useEffect(() => {
        if (!paymentSuccessful) {
            navigate('/');
        }
    }, []);

    return (
        <>
            <div className="min-h-screen grid grid-cols-1 md:grid-cols-2">
                {/* Left Column */}
                <div className="bg-primary px-14 pb-10 flex-col items-center justify-center hidden md:flex">
                    <img
                        src={IMAGES.register}
                        className='register-img'
                        alt='Register'
                    />
                    <h2 className="font-36 font-bold text-white self-start mt-2">User and Company Information</h2>
                    <p
                        className='font-16 font-normal text-white mt-2'
                    >
                        Please complete your details below so we can create a Company for you. The user details you specify here will be for the initial account owner of this company.
                    </p>
                </div>
                {/* Right Column */}
                <div className="bg-white px-10 py-5 pt-10">
                    <a href="/" className="p-1.5 flex items-center justify-center space-x-2"
                    >
                        <img
                            alt=""
                            src={IMAGES.logoGreen}
                            className="h-8"
                        />
                    </a>
                    <div
                        className='mt-8'
                    >
                        <h2 className="font-24 md:text-[32px] lg:text-[36px] font-bold text-secondary self-start mt-2">Store Registration</h2>
                        <p
                            className='font-16 font-normal text-darkGrey mt-2'
                        >
                            Enter the details below to create an account for you.
                        </p>
                        <form onSubmit={handleSubmit} className="space-y-2">
                            <div
                                className='mt-8'
                            >
                                <div className='flex-1 items-center'>
                                    <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                        Company Name (CIPC)
                                    </h2>
                                    <input
                                        type="text"
                                        className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                        placeholder="Type here.."
                                    />
                                </div>
                                <div className='flex flex-col md:flex-row md:items-center gap-5 md:gap-6 lg:gap-10 mt-5'>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Trading Name
                                        </h2>
                                        <input
                                            type="text"
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Franschise group
                                        </h2>
                                        <input
                                            type="text"
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row md:items-center gap-5 md:gap-6 lg:gap-10 mt-5'>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Store Name
                                        </h2>
                                        <input
                                            id="storeName"
                                            name="storeName"
                                            type="text"
                                            value={formData.storeName}
                                            onChange={handleChange}
                                            required
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Primary Email
                                        </h2>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            autoComplete="email"
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row md:items-center gap-5 md:gap-6 lg:gap-10 mt-5'>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Address
                                        </h2>
                                        <input
                                            id="address"
                                            name="address"
                                            type="text"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            autoComplete="address"
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Contact Number
                                        </h2>
                                        <input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="tel"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            required
                                            autoComplete="tel"
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-col md:flex-row md:items-center gap-5 md:gap-6 lg:gap-10 mt-5'>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Bank Name
                                        </h2>
                                        <div className="relative">
                                            <select
                                                id="bankName"
                                                name="bankName"
                                                value={formData.bankName}
                                                onChange={handleChange}
                                                required
                                                className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3 appearance-none`}
                                            >
                                                <option value="" disabled>Select a bank</option>
                                                {
                                                    banks.map((bank) => (
                                                        <option
                                                            key={bank.code}
                                                            value={bank.code}
                                                        >
                                                            {bank.name}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {/* Custom arrow */}
                                            <div className="absolute inset-y-0 right-0 top-3 flex items-center pr-2 pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="currentColor">
                                                    <path d="M7 7l3-3 3 3M7 13l3 3 3-3" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='flex-1 items-center'>
                                        <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                            Account Number
                                        </h2>
                                        <input
                                            id="accountNumbr"
                                            name="accountNumbr"
                                            type="text"
                                            value={formData.accountNumbr}
                                            onChange={handleChange}
                                            required
                                            className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                            placeholder="Type here.."
                                        />
                                    </div>
                                </div>

                                <div className='flex-1 items-center mt-5'>
                                    <h2 className="text-secondary font-14 md:text-[16px] font-semibold custom-font">
                                        Password
                                    </h2>
                                    <div className="relative w-full">
                                        <input
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            autoComplete="current-password"
                                            className="text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3 pr-10" // Adjust padding for icon
                                            placeholder="Type here.."
                                        />

                                        {/* Eye Icon Button */}
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-3 flex items-center mt-3"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <EyeOff size={20} className="text-gray-500" /> : <Eye size={20} className="text-gray-500" />}
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className='flex items-center justify-end mt-8 gap-3'
                                >
                                    <button
                                        type="button"
                                        className="flex justify-center rounded-xl border-primary px-8 py-1.5 text-sm font-semibold leading-6 text-primary focus-visible:outline"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="flex justify-center rounded-xl border-primary bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline "
                                    >
                                        Finish Registration
                                    </button>
                                </div>
                            </div>
                            {isRegistering && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50" style={{ marginTop: 0 }}>
                                    <div className="bg-white p-4 rounded-md shadow-lg">
                                        <p className="text-lg font-semibold">Registering your shop...</p>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterPrintShop;
