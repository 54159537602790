import React, { useCallback, useEffect, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs, updateDoc, doc, where, query, getDoc, serverTimestamp } from 'firebase/firestore';
import NavBar from "../components/NavBar";
import { COLORS } from "../utils/theme";
import { db, storage } from '../firebase/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx'; // Import xlsx for Excel file handling
import jsPDF from 'jspdf'; // Import jsPDF for PDF generation
import { ICONS } from '../utils/icons';
import MySelector from '../components/MySelector';
import MyToggleButton from '../components/MyToggleButton';
import { CSSTransition } from "react-transition-group";
import PrintShopItem from '../components/PrintShopsItem';
import Footer from '../components/Footer';
import { useSearchParams } from "react-router-dom";



const Home = () => {
    const [fileList, setFileList] = useState([]);
    const [emailAddress, setEmailAddress] = useState('');
    const [printNeed, setPrintNeed] = useState('');
    const [storeID, setStoreID] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedShop, setSelectedShop] = useState("");
    const [stores, setStores] = useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    const [searchParams] = useSearchParams();
    const storeName = searchParams.get("storename") || "";
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [isOnlinePaid, setIsOnlinePaid] = useState(false);
    const location = useLocation();
    const { printRequestId, currentIndex } = location.state || {};


    const printTypeOptions = ["Normal", "Building Plans", "Booklet", "Canvas", "Flyer", "Poster", "Custom"];
    const paperSizeOptions = ["A0", "A1", "A2", "A3", "A4"];
    const paperThicknessOptions = ["80 GSM: Normal", "90 GSM", "100 GSM"];
    const orientationOptions = ["Portrait", "Landscape"];



    const [printType, setPrintType] = useState('');
    const [paperSize, setPaperSize] = useState('');
    const [color, setColor] = useState('');
    const [doubleSided, setDoubleSided] = useState('');
    const [binding, setBinding] = useState('');
    const [paperThickness, setPaperThickness] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [orientation, setOrientation] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [subAccountCode, setSubAccountCode] = useState('');


    const [pricingData, setPricingData] = useState([]);
    const [buildingPlansPricingData, setBuildingPlansPricingData] = useState([]);
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);

    const [errors, setErrors] = useState({});


    useEffect(() => {
        const paymentSuccess = localStorage.getItem('paymentSuccess');

        if (paymentSuccess === 'true') { // Check if payment was successful
            setIsOnlinePaid(true);
            localStorage.removeItem('paymentSuccess');
        }
    }, []);


    useEffect(() => {
        const fetchPrintRequestData = async () => {
            if (printRequestId) {
                try {
                    // Reference to the document in Firestore
                    const docRef = doc(db, 'print_requests', printRequestId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setEmailAddress(data.customer_email);
                        setPrintNeed(data.printNeed);
                        setFileList(data.files);
                        setStoreID(data.storeID);
                        if (!data.totalPrice || data.totalPrice === 0) {
                            const totalPrice = data.files.reduce((sum, file) => sum + (file.price || 0), 0);
                            setTotalPrice(totalPrice);
                        } else {
                            setTotalPrice(data.totalPrice);
                        }
                        if (currentIndex) {
                            setCurrentPdfIndex(currentIndex);
                            setBinding(data.files[currentIndex].printOptions.binding);
                            setColor(data.files[currentIndex].printOptions.color);
                            setDoubleSided(data.files[currentIndex].printOptions.doubleSided);
                            setPaperSize(data.files[0].printOptions.paperSize);
                            setPaperThickness(data.files[currentIndex].printOptions.paperThickness);
                            setPrintType(data.files[currentIndex].printOptions.printType);
                            setQuantity(data.files[currentIndex].printOptions.quantity);
                            setOrientation(data.files[currentIndex].printOptions.orientation);
                        } else {
                            setBinding(data.files[0].printOptions.binding);
                            setColor(data.files[0].printOptions.color);
                            setDoubleSided(data.files[0].printOptions.doubleSided);
                            setPaperSize(data.files[0].printOptions.paperSize);
                            setPaperThickness(data.files[0].printOptions.paperThickness);
                            setPrintType(data.files[0].printOptions.printType);
                            setQuantity(data.files[0].printOptions.quantity);
                            setOrientation(data.files[0].printOptions.orientation);
                        }

                        // Fetch user details based on storeID
                        const userDocRef = doc(db, 'users', data.storeID);
                        const userDocSnap = await getDoc(userDocRef);

                        if (userDocSnap.exists()) {
                            const userData = userDocSnap.data();
                            setSelectedShop(userData.storeName);
                        } else {
                            console.error('No user found for this storeID');
                        }

                    } else {
                        console.error('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching document:', error);
                }
            }
        };

        fetchPrintRequestData();
    }, [printRequestId]);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const storesCollection = collection(db, "users");
                const storesSnapshot = await getDocs(storesCollection);
                const storesList = storesSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id // Add this line to get the document ID
                }));
                setStores(storesList);
                setFilteredStores(storesList);
                if (storeName) {
                    const selectedStore = storesList.find(store => store.storeName === storeName);
                    if (selectedStore) {
                        setSelectedShop(storeName);
                        setStoreID(selectedStore.id);
                    }
                }
            } catch (error) {
                console.error("Error fetching stores: ", error);
            }
        };
        fetchStores();
    }, []);

    // Handle input change and filter stores
    const handleShopInputChange = (e) => {
        const value = e.target.value;
        setSelectedShop(value);

        if (value.trim() !== "") {
            const filtered = stores.filter((store) =>
                store.storeName.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredStores(filtered);
        } else {
            setFilteredStores(stores); // ✅ Show all stores when input is empty
        }

        setIsOpen(true); // ✅ Always show the dropdown when typing
    };


    // Handle selecting a store
    const hanldeSelectedShop = async (shop, shopID) => {
        setSelectedShop(shop);
        setErrors({ shop: false });
        if (shopID) {
            setStoreID(shopID); // Set the store ID
            try {
                // Reference to the users collection
                const usersCollection = collection(db, 'users');

                // Query to find the document with the matching storeID field
                const q = query(usersCollection, where('id', '==', shopID));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    setPricingData(userData.pricing);
                    setBuildingPlansPricingData(userData.buildingPlanPricing);
                    setSubAccountCode(userData.subaccountCode)
                } else {
                    console.log('No user found with the provided storeID');
                }
            } catch (err) {
                console.log(err.message);
            }

        }
        setIsOpen(false);
        // Delay price calculation to ensure state updates first
        setTimeout(() => {
            calculatePrice();
        }, 0);
    };


    // Function to extract page count from the PDF
    const getPdfPageCount = async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        return pdfDoc.getPageCount();
    };


    const uploadFiles = async (files) => {
        const uploadPromises = files.map(async (fileData) => {
            const { file, fileName } = fileData; // Extract file and filename
            const storageRef = ref(storage, `files/${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    null,
                    (error) => {
                        console.error("File upload error:", error);
                        reject(error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                        // Return a new object without the 'file' property
                        const { file, ...rest } = fileData; // Destructure to remove 'file'
                        resolve({
                            ...rest, // Keep all other properties
                            url: downloadURL, // Add the URL
                        });
                    }
                );
            });
        });

        const uploadedFiles = await Promise.all(uploadPromises);

        // Update fileList with the cleaned-up files (no file property)
        setFileList(uploadedFiles);
    };



    const handleFileInput = async (event) => {
        setIsUploading(true);
        const files = event.target.files;
        const validFileTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'image/jpeg', // JPEG image
            'image/png', // PNG image
        ];

        // Filter files to check for Excel files in advance
        const excelFiles = Array.from(files).filter(file =>
            file.type.includes("sheet") || file.type.includes("excel")
        );

        // Alert if multiple Excel files are selected
        if (excelFiles.length > 1) {
            alert("You can only upload one Excel file at a time.");
            setIsUploading(false);
            return;
        }


        const selectedFiles = await Promise.all(
            Array.from(files).map(async (file) => {
                if (!validFileTypes.includes(file.type)) return null; // Ignore unsupported files

                let pageCount = 1; // Default page count

                if (file.type === 'application/pdf') {
                    pageCount = await getPdfPageCount(file); // Get actual PDF page count
                }
                else if (file.type.includes("sheet") || file.type.includes("excel")) {
                    pageCount = prompt("Enter the number of pages you want to print for this Excel file. Tip: Save the Excel file as a PDF and check how many pages are produced.");
                    if (!pageCount || isNaN(pageCount) || pageCount <= 0) {
                        alert("Invalid page count. Please enter a valid number.");
                        return null;
                    }
                    pageCount = parseInt(pageCount);
                }
                else if (file.type.startsWith('image/')) {
                    pageCount = 1; // Images count as 1 page
                }

                return {
                    file,
                    fileName: file.name,
                    fileType: file.type,
                    pageCount,
                    printOptions: {
                        printType: '',
                        paperSize: '',
                        quantity: 1,
                        color: '',
                        binding: '',
                        doubleSided: '',
                        paperThickness: '',
                        orientation: '',
                    },
                    price: 0
                };
            })
        );

        // Filter out null results and set fileList
        const filteredFiles = selectedFiles.filter(file => file !== null);
        setFileList(filteredFiles); // Now fileList includes both file and pageCount
        await uploadFiles(filteredFiles); // Upload the files
        setIsUploading(false); // Reset the uploading state
    };


    const handleOptionChange = (optionName, value) => {
        if (fileList.length === 0) return;

        setFileList(prevFileList => {
            if (!Array.isArray(prevFileList)) return [];

            return prevFileList.map((file, index) =>
                index === currentPdfIndex
                    ? {
                        ...file,
                        printOptions: {
                            ...file.printOptions,
                            [optionName]: value
                        }
                    }
                    : file
            );
        });

        // Clear error if the field is being updated
        setErrors(prevErrors => ({
            ...prevErrors,
            [optionName]: false // Set error to false when the field is filled
        }));

        switch (optionName) {
            case 'printType': setPrintType(value); break;
            case 'paperSize': setPaperSize(value); break;
            case 'quantity': setQuantity(value); break;
            case 'paperThickness': setPaperThickness(value); break;
            case 'orientation': setOrientation(value); break;
            case 'color': setColor(value); break;
            case 'binding': setBinding(value); break;
            case 'doubleSided': setDoubleSided(value); break;
            default: console.warn(`No state found for option: ${optionName}`);
        }
    };


    useEffect(() => {
        if (fileList.length > 0) {
            calculatePrice();
        }
    }, [JSON.stringify(fileList)]); // Convert to string to avoid unnecessary re-renders



    const handleDateClick = () => {
        document.getElementById('printNeed').showPicker();
    };


    const handleRemoveFile = (index) => {
        setFileList(prevFileList => prevFileList.filter((_, i) => i !== index));
    };


    const calculatePrice = useCallback(() => {
        const isBuildingPlans = printType === "Building Plans";
        const pricingDataToUse = isBuildingPlans ? buildingPlansPricingData : pricingData;

        if (fileList.length > 0 && paperSize && color && pricingDataToUse.length > 0 && quantity !== 0) {
            if (currentPdfIndex < 0 || currentPdfIndex >= fileList.length) {
                console.error("Invalid currentPdfIndex:", currentPdfIndex);
                return;
            }

            setFileList(prevFileList => {
                const updatedFiles = [...prevFileList];
                const currentFile = updatedFiles[currentPdfIndex];
                const { pageCount } = currentFile;

                let filePrice = 0;
                let pricing = null;

                if (isBuildingPlans) {
                    pricing = pricingDataToUse.find(item => item.type === color);
                    if (pricing) {
                        const sizePricing = pricing.sizes.find(sizeItem => sizeItem.size === paperSize);
                        if (sizePricing) {
                            filePrice = sizePricing.price * pageCount * quantity;
                        }
                    }
                } else {
                    pricing = pricingDataToUse.find(
                        (item) => item.paperSize === paperSize && item.color === color
                    );
                    if (pricing) {
                        const priceRange = pricing.priceRanges.find((range) => {
                            const [minPages, maxPages] = range.pages.split('-').map(str => parseInt(str.replace(/\D/g, ''), 10));
                            return pageCount >= minPages && (maxPages ? pageCount <= maxPages : true);
                        });
                        if (priceRange) {
                            filePrice = priceRange.price * pageCount * quantity;
                        }
                    }
                }

                if (pricing && filePrice > 0) {
                    updatedFiles[currentPdfIndex] = {
                        ...currentFile,
                        price: filePrice,
                        printOptions: {
                            printType,
                            paperSize,
                            quantity,
                            color,
                            binding,
                            doubleSided,
                            paperThickness,
                            orientation
                        },
                    };

                    return updatedFiles;
                } else {
                    console.error('No matching pricing data found');
                    return prevFileList;
                }
            });

            setTotalPrice((prevFileList) => {
                const validFileList = Array.isArray(fileList) ? fileList : []; // Use fileList instead of prevFileList
                return validFileList.reduce((acc, file) => acc + (file?.price || 0), 0);
            });



        } else {
            setErrors({
                paperSize: !paperSize,
                color: !color,
                shop: pricingDataToUse.length === 0,
                quantity: quantity === 0,
            });
            console.error('Missing data for price calculation');
        }
    }, [fileList, paperSize, color, quantity, printType, currentPdfIndex, buildingPlansPricingData, pricingData]);




    const handlePrintNow = async () => {
        const requiredFields = { printType, emailAddress, printNeed, storeID, paperSize, color, binding, doubleSided, paperThickness, totalPrice, orientation };
        const emptyFields = Object.entries(requiredFields)
            .filter(([_, value]) => !value)
            .map(([key]) => key);

        if (fileList.length === 0 || emptyFields.length > 0) {
            setErrors((prev) => ({
                ...prev,
                ...Object.fromEntries(emptyFields.map((key) => [key, true]))
            }));

            console.log("Empty Fields:", emptyFields);
            return;
        }

        if (!fileList.every(file => Number(file.price) > 0)) {
            alert('One or more files have a price of 0. Please ensure that all prices are calculated.');
            return;
        }

        setIsUploading(true);

        try {
            if (printRequestId) {
                // ✅ Update Existing Document
                const docRef = doc(db, 'print_requests', printRequestId);
                await updateDoc(docRef, {
                    files: fileList,
                    totalPrice,
                    customer_email: emailAddress,
                    printNeed,
                    storeID,
                    storeName: selectedShop,
                    status: 'Pending',
                    updatedAt: serverTimestamp(),
                });

                navigate('/checkout', { state: { documentId: printRequestId, fileList, totalPrice, emailAddress, subAccountCode, selectedShop } });

            } else {
                // ✅ Create New Document
                const docRef = await addDoc(collection(db, 'print_requests'), {
                    files: fileList,
                    customer_email: emailAddress,
                    printNeed,
                    storeID,
                    storeName: selectedShop,
                    createdAt: serverTimestamp(),
                    status: 'Pending',
                });

                await updateDoc(doc(db, 'print_requests', docRef.id), { id: docRef.id });

                navigate('/checkout', { state: { documentId: docRef.id, fileList, totalPrice, emailAddress, subAccountCode, selectedShop } });
            }

            setIsUploading(false);

        } catch (e) {
            console.error("Error processing print request:", e);
            setIsUploading(false);
        }
    };




    return (
        <div className="bg-white">
            <Helmet>
                <meta name="description" content="Get the best of all the printing shops." />
                <meta property="og:title" content="Signet Print" />
                <meta property="og:description" content="Get the best of all the printing shops." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/print-hub-4es1ey.appspot.com/o/logo.png?alt=media&token=9ead630a-dd40-4356-b7a7-d9356ed00524" />
                <meta property="og:url" content="https://print-hub-4es1ey.web.app/" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <NavBar />
            <div className="relative isolate min-h-screen px-4 pt-8 md:px-10 lg:px-40">
                <div className="py-16 sm:py-24">
                    <h2 className='text-center text-primary font-36 md:text-[48px] font-bold custom-font'>Priced, Printed & Paid</h2>
                    <p className='text-center text-darkGrey font-18 md:text-[20px] font-regular custom-font'>Enjoy hassle free printing through signet print, just put down your requirements and get your files printed from a nearby print shop.</p>

                    <div className="bg-white upload-files-main mt-10 flex flex-col pb-5 px-4 md:px-8 overflow-x-auto">
                        <div className="flex-1 p-2 pt-4 pb-4 overflow-y-auto custom-scroll relative overflow-x-hidden">
                            <div className="flex items-center justify-between">
                                {/* Left Side Content */}
                                <div className="mr-3">
                                    <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font">
                                        Upload Files
                                    </h2>
                                    <p className="text-darkGrey font-14 font-regular custom-font">
                                        Please upload the files you want to get printed and select print options for each of them.
                                    </p>
                                </div>

                                {/* Right Side Button */}
                                <button
                                    onClick={() => document.getElementById("fileInput").click()}
                                    className="p-2.5 flex items-center btn-grey text-sm md:text-base font-medium min-w-[140px] md:w-auto"
                                >
                                    <img alt="Upload" src={ICONS.documentUpload} className="h-5 pr-2" />
                                    Upload Files
                                </button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    multiple
                                    accept=".pdf,.xlsx,.xls,image/*" // Accept PDF, Excel, and image files
                                    onChange={handleFileInput}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            {fileList.length > 0 &&
                                <div className="mt-8 overflow-x-auto">
                                    <div className="flex flex-col md:flex-row gap-4">
                                        {fileList.map((file, index) => (
                                            <div key={index} className={`print-details-item mt-3 mb-3 ml-1 min-w-[300px] ${currentPdfIndex === index ? 'bg-primary' : ''}`}>
                                                <div className="flex items-center justify-between min-w-0">
                                                    {/* File Name & Icon */}
                                                    <div className="flex items-center min-w-0 flex-1">
                                                        <img alt="PDF" src={ICONS.pdf} className="h-5 flex-shrink-0 mr-2" />
                                                        <p className={`${currentPdfIndex === index ? 'text-white' : 'text-darkGrey'} font-16 font-normal overflow-hidden text-ellipsis max-w-[80%] md:max-w-full`}>
                                                            {file.fileName}
                                                        </p>
                                                    </div>

                                                    {/* Edit & Delete Buttons (No Wrapping) */}
                                                    <div className="flex items-center gap-x-2 flex-shrink-0 flex-nowrap ml-2">
                                                        <button onClick={() => setCurrentPdfIndex(index)} className="whitespace-nowrap">
                                                            <img
                                                                alt="Edit"
                                                                src={ICONS.edit}
                                                                className={`h-6 md:h-5 ${currentPdfIndex === index ? 'invert brightness-0' : 'brightness-100'}`}
                                                            />
                                                        </button>
                                                        <button onClick={() => handleRemoveFile(index)} className="whitespace-nowrap">
                                                            <img
                                                                alt="Delete"
                                                                src={ICONS.delete}
                                                                className={`h-6 md:h-5 ${currentPdfIndex === index ? 'grayscale brightness-0' : 'grayscale-0'}`}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Description & Price */}
                                                <p className={`${currentPdfIndex === index ? 'text-white' : 'text-darkGrey'} font-14 font-regular custom-font break-words`}>
                                                    {Object.entries(file.printOptions)
                                                        .filter(([key, value]) => value !== "" && value !== null && value !== undefined)
                                                        .map(([key, value]) => value)
                                                        .join(", ")} |
                                                    <span className={`${currentPdfIndex === index ? 'text-white' : 'text-secondary'} font-bold`}> R{file.price}</span>
                                                </p>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className='hr-divider mt-5 mb-5' />
                            <div className='flex flex-col md:flex-row md:items-center gap-5'>
                                <div className="flex-1 items-center relative">
                                    <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font">
                                        Search
                                    </h2>
                                    <input
                                        type="text"
                                        value={selectedShop}
                                        onChange={handleShopInputChange}
                                        onFocus={() => setIsOpen(true)}
                                        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
                                        className={`text-textBlack border ${errors.shop ? 'border-danger' : 'border-borderColor'}  placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16 mt-3`}
                                        placeholder="search for shop/location"
                                    />
                                    <CSSTransition in={isOpen} timeout={300} classNames="dropdown" unmountOnExit>
                                        <div className="my-dropdown-list absolute top-full left-0 w-full bg-white border border-borderColor shadow-lg rounded-md mt-1 pt-2 z-10">
                                            {filteredStores.length > 0 ? (
                                                filteredStores.map((option, index) => (
                                                    <div
                                                        key={index}
                                                        className="search-dropdown-item px-6 py-3 cursor-pointer hover:bg-gray-100"
                                                        onClick={() => hanldeSelectedShop(option.storeName, option.id)}
                                                    >
                                                        {option.storeName}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="my-dropdown-item px-4 py-2 text-gray-500">
                                                    No results found
                                                </div>
                                            )}
                                        </div>
                                    </CSSTransition>
                                </div>
                                <div className='flex-1 items-center'>
                                    <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font mb-3">
                                        Email Address
                                    </h2>
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        value={emailAddress}
                                        onChange={(event) => setEmailAddress(event.target.value)}
                                        className={`text-textBlack border ${errors.emailAddress ? 'border-danger' : 'border-borderColor'} placeholder-lightGrey px-4 py-2 rounded-xl outline-none w-full font-16`}
                                        placeholder="Email Address"
                                    />
                                </div>
                            </div>
                            <div className='flex items-center gap-5 mt-5'>
                                <div className='flex-1 items-center'>
                                    <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font">
                                        Print Options
                                    </h2>
                                    <div className='flex flex-col md:flex-row md:items-center gap-3 mt-3'>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Print Type</p>
                                            <MySelector
                                                options={printTypeOptions}
                                                placeHolder="Select Print Type"
                                                disabledOptions={["Booklet", "Canvas", "Flyer", "Poster", "Custom"]}
                                                handleSaveState={(val) => handleOptionChange('printType', val)}
                                                error={errors.printType}
                                                defaultOption={printType && printType}
                                            />

                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Paper Size</p>
                                            <MySelector
                                                options={paperSizeOptions}
                                                placeHolder="Select Paper Size"
                                                disabledOptions={printType === "Building Plans" ? ["A3", "A4"] : ["A0"]}
                                                handleSaveState={(val) => handleOptionChange('paperSize', val)}
                                                error={errors.paperSize}
                                                defaultOption={paperSize && paperSize}
                                            />

                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Paper Thickness</p>
                                            <MySelector
                                                options={paperThicknessOptions}
                                                placeHolder="Select Thickness"
                                                handleSaveState={(val) => handleOptionChange('paperThickness', val)}
                                                error={errors.paperThickness}
                                                defaultOption={paperThickness && paperThickness}
                                            />
                                        </div>

                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Quantity</p>
                                            <input
                                                name="quantity"
                                                id="quantity"
                                                type="number"
                                                min={1}
                                                value={quantity}
                                                placeHolder="Enter Quantity"
                                                onChange={(event) => handleOptionChange('quantity', event.target.value)}
                                                className={`text-textBlack border ${errors.quantity ? 'border-danger' : 'border-borderColor'} placeholder-lightGrey px-4 py-2 rounded-xl outline-none font-16 w-full`}
                                            />
                                            {/* <MySelector
                                                options={quantityOptions}
                                                placeHolder="Select Quantity"
                                                handleSaveState={(val) => handleOptionChange('quantity', val)}
                                                error={errors.quantity}
                                                defaultOption={quantity && quantity}
                                            /> */}
                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>When</p>
                                            <input
                                                name="printNeed"
                                                id="printNeed"
                                                type="date"
                                                value={printNeed}
                                                onChange={(event) => setPrintNeed(event.target.value)}
                                                onClick={handleDateClick}
                                                className={`cursor-pointer text-textBlack border ${errors.printNeed ? 'border-danger' : 'border-borderColor'} placeholder-lightGrey px-4 py-2 rounded-xl outline-none font-16 w-full`}
                                                placeholder='27/01/2025 - 4:00 PM'
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row md:items-center gap-3 mt-3'>

                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Orientation</p>
                                            <MySelector
                                                options={orientationOptions}
                                                placeHolder="Select Orientation"
                                                handleSaveState={(val) => handleOptionChange('orientation', val)}
                                                error={errors.orientation}
                                                defaultOption={orientation && orientation}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Color</p>
                                            <MyToggleButton
                                                type="Color"
                                                handleSaveState={(val) => handleOptionChange('color', val)}
                                                disableYes={false}
                                                error={errors.color}
                                                defaultSelection={color && color}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Binding</p>
                                            <MyToggleButton
                                                type="Binding"
                                                handleSaveState={(val) => handleOptionChange('binding', val)}
                                                disableYes={false}
                                                error={errors.binding}
                                                defaultSelection={binding && binding}
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <p className='text-textBlack font-medium font-14 mb-3'>Double Sided</p>
                                            <MyToggleButton
                                                type="Double Sided"
                                                handleSaveState={(val) => handleOptionChange('doubleSided', val)}
                                                disableYes={false}
                                                error={errors.doubleSided}
                                                defaultSelection={doubleSided && doubleSided}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:items-center md:justify-between mt-8 flex-col md:flex-row'>
                                <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font mb-3">
                                    Total Price: <span className='text-primary'>R{totalPrice}</span>
                                </h2>
                                <button
                                    onClick={handlePrintNow}
                                    className="text-white px-5 py-3 rounded-xl bg-primary font-semibold font-16 self-center md:self-end md:mt-0 mt-3"
                                >
                                    Go to checkout
                                </button>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className="flex items-center gap-5 mt-8">
                            <div className="flex-1 items-center relative">
                                <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font">
                                    How does it work
                                </h2>
                            </div>
                        </div>

                        {/* Divider - Hidden on Mobile */}
                        <div className="md:flex flex-col w-full">
                            <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-7 gap-5 mt-8">
                                {/* Step 1 */}
                                <div className="justify-items-center text-center">
                                    <div className="bg-oceanBlue p-4 rounded-2xl w-20 h-20 flex items-center justify-center">
                                        <img src={ICONS.search} className="w-6 h-6" />
                                    </div>
                                    <h2 className="text-textBlack font-20 font-semibold mt-5 mb-1">
                                        Step 1
                                    </h2>
                                    <p className="text-darkGrey font-medium font-14">Select your print shop</p>
                                </div>

                                {/* Divider - Hidden on Mobile */}
                                <div className="hidden md:flex items-center justify-center">
                                    <div className="w-3 h-3 bg-oceanBlue rounded-full" />
                                    <hr className="hr-divider-gradient flex-1 opacity-25 bg-[linear-gradient(90deg,transparent,transparent_50%,#fff_50%,#fff_100%),linear-gradient(90deg,rgba(150,114,255,1),rgba(77,223,253,1))]" />
                                    <div className="w-3 h-3 bg-darkGreen rounded-full mr-1" />
                                </div>

                                {/* Step 2 */}
                                <div className="justify-items-center text-center mt-5 sm:mt-0">
                                    <div className="bg-darkGreen p-4 rounded-2xl w-20 h-20 flex items-center justify-center">
                                        <img src={ICONS.upload} className="w-6 h-6" />
                                    </div>
                                    <h2 className="text-textBlack font-20 font-semibold mt-5 mb-1">
                                        Step 2
                                    </h2>
                                    <p className="text-darkGrey font-medium font-14">
                                        Upload the documents you need printed
                                    </p>
                                </div>

                                {/* Divider - Hidden on Mobile */}
                                <div className="hidden md:flex items-center justify-center">
                                    <div className="w-3 h-3 bg-darkGreen rounded-full" />
                                    <hr className="hr-divider-gradient flex-1 opacity-25 bg-[linear-gradient(90deg,transparent,transparent_50%,#fff_50%,#fff_100%),linear-gradient(90deg,rgba(150,114,255,1),rgba(77,223,253,1))]" />
                                    <div className="w-3 h-3 bg-oceanBlue rounded-full mr-1" />
                                </div>

                                {/* Step 3 */}
                                <div className="justify-items-center text-center  mt-5 sm:mt-0">
                                    <div className="bg-softPink p-4 rounded-2xl w-20 h-20 flex items-center justify-center">
                                        <img src={ICONS.files} className="w-6 h-6" />
                                    </div>
                                    <h2 className="text-textBlack font-20 font-semibold mt-5 mb-1">
                                        Step 3
                                    </h2>
                                    <p className="text-darkGrey font-medium font-14">
                                        Select requirements needed for printing and get an accurate costing
                                    </p>
                                </div>

                                {/* Divider - Hidden on Mobile */}
                                <div className="hidden md:flex items-center justify-center">
                                    <div className="w-3 h-3 bg-softPink rounded-full" />
                                    <hr className="hr-divider-gradient flex-1 opacity-25 bg-[linear-gradient(90deg,transparent,transparent_50%,#fff_50%,#fff_100%),linear-gradient(90deg,rgba(150,114,255,1),rgba(77,223,253,1))]" />
                                    <div className="w-3 h-3 bg-primary rounded-full mr-1" />
                                </div>

                                {/* Step 4 */}
                                <div className="justify-items-center text-center mt-5 sm:mt-0">
                                    <div className="bg-primary p-4 rounded-2xl w-20 h-20 flex items-center justify-center">
                                        <img src={ICONS.document} className="w-6 h-6" />
                                    </div>
                                    <h2 className="text-textBlack font-20 font-semibold mt-5 mb-1">
                                        Step 4
                                    </h2>
                                    <p className="text-darkGrey font-medium font-14">
                                        Pay online and collect your printed documents in-store, or pay in-store and wait for your documents to be printed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-12 md:mt-8">
                        <div className="flex items-center justify-between">
                            <h2 className="text-secondary font-20 md:text-[24px] font-semibold custom-font">
                                Suggested Printing Shops Near You
                            </h2>
                            <a
                                href='/stores'
                                className='text-secondary font-16 font-medium'
                            >See all</a>
                        </div>

                        <div className="flex flex-col mt-5">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                                {stores.map((item, index) => (
                                    <PrintShopItem key={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

            {isUploading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50" style={{ marginTop: 0 }}>
                    <div className="bg-white p-4 rounded-md shadow-lg">
                        {/* Circular Progress Spinner */}
                        <div className="spinner"></div>
                    </div>
                </div>
            )}

            {
                isOnlinePaid &&
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50" style={{ marginTop: 0 }}>
                    <div className="bg-white p-4 rounded-md shadow-lg ml-5 mr-5 text-center">
                        <div className="cross-icon flex justify-end">
                            <span
                                onClick={() => setIsOnlinePaid(false)}
                                className="cursor-pointer"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={COLORS.black} className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>

                        </div>

                        <p className="text-lg font-medium text-center">
                            Thank you for your order! <br></br>
                            We've received your PDF, email, and desired pickup date. You may come collect your order on the given pickup date.
                        </p>

                    </div>
                </div>
            }
        </div>

    );
};

export default Home;
