import React from "react";
import { IMAGES } from "../utils/images";
import { ICONS } from "../utils/icons";

const PrintShopItem = ({ item }) => {
    return (
        <div className="w-full flex flex-col">
            <img
                src={IMAGES.dummy}
                className="w-full max-h-72 h-auto rounded-2xl object-cover"
            />

            <div className="flex items-center justify-between">
                <h2 className="text-textBlack font-14 font-semibold mt-2">
                    {item.storeName}
                </h2>
                <div className="flex items-center gap-1">
                    <img
                        src={ICONS.ratingStart}
                        className="w-5 h-5"
                    />
                    <p className="text-textBlack font-semibold font-14">
                        4.7
                    </p>
                </div>
            </div>
            <p className="text-darkGrey font-medium font-14">
                {item.address}
            </p>
            <p className="text-darkGrey font-semibold font-14">
                {item.phoneNumber}
            </p>
            <p className="text-textBlack font-medium font-14">
                R
            </p>
        </div>

    );
};

export default PrintShopItem;
