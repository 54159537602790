/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ICONS } from "../utils/icons";
import { IMAGES } from "../utils/images";

const Footer = () => {
    return (
        <div className="w-full px-12 md:px-24 py-24 bg-[#F9F8FA]">
            <div className="flex items-center justify-between">
                <div className="flex flex-col md:flex-row items-center md:gap-14 gap-y-3">
                    <a
                        href="/pricing"
                        className="hover:opacity-80"
                    >
                        <h2 className="text-darkGrey font-16 font-normal">
                            List my Shop
                        </h2>
                    </a>
                    <a
                        href="/printing-jobs"
                        className="hover:opacity-80"
                    >
                        <h2 className="text-darkGrey font-16 font-normal">
                            My Printing Jobs
                        </h2>
                    </a>
                    <a
                        href="/shop-login"
                        className="hover:opacity-80"
                    >
                        <h2 className="text-darkGrey font-16 font-normal">
                            Store Login
                        </h2>
                    </a>
                </div>
                {/* <div className="flex items-center gap-5">
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <img
                            src={ICONS.facebook}
                            className="w-7 h-7 object-contain" />
                    </a>
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <img
                            src={ICONS.twitter}
                            className="w-7 h-7 object-contain" />
                    </a>
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <img
                            src={ICONS.vimeo}
                            className="w-7 h-7 object-contain" />
                    </a>
                    <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <img
                            src={ICONS.youtube}
                            className="w-7 h-7 object-contain" />
                    </a>
                </div> */}
            </div>

            <hr className="hr-divider mt-10 mb-10" />

            <div className="flex items-center justify-between">

                <h2 className="text-darkGrey font-14 font-normal">
                    © All rights reserved.
                </h2>
                <img
                    alt=""
                    src={IMAGES.logoGreen}
                    className="h-8 hidden md:block"
                />
                <div className="flex items-center gap-4">
                    {/* <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <h2 className="text-darkGrey font-14 font-normal">
                            Terms of Service
                        </h2>
                    </a>
                    <a
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:opacity-80"
                    >
                        <h2 className="text-darkGrey font-14 font-normal">
                            Privacy Policy
                        </h2>
                    </a> */}
                </div>

            </div>

        </div>

    );
};

export default Footer;
