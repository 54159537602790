import HeadOfficeNavBar from '../../components/HeadOfficeNavBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { ICONS } from '../../utils/icons';


const HeadOfficePrintDetails = () => {
    const location = useLocation();
    const [job, setJob] = useState({});
    const navigate = useNavigate();
    const { jobId } = location.state || {};
    const [user, setUser] = useState([]);
    const [isSending, setIsSending] = useState(false);


    useEffect(() => {
        // Fetch user data from local storage
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            setUser(parsedUserData);
        }
    }, []); // Only run once on mount


    useEffect(() => {
        const fetchPrintRequestData = async () => {
            if (jobId) {
                try {
                    const docRef = doc(db, 'print_requests', jobId);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setJob(data);
                        console.log(data); // Log the data here
                    } else {
                        console.error('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching document:', error);
                }
            }
        };

        fetchPrintRequestData();
    }, [jobId]);



    const handleJobCompleteClick = async () => {
        if (job) {
            try {
                // Reference to the document in Firestore
                const docRef = doc(db, 'print_requests', job.id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {

                    await updateDoc(docRef, {
                        status: 'Completed'
                    });
                    navigate('/admin/dashboard');

                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        }

    }

    const handleSendEmail = async () => {
        if (job) {
            try {
                setIsSending(true);
                // Reference to the document in Firestore
                const docRef = doc(db, 'print_requests', job.id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {

                    await updateDoc(docRef, {
                        status: 'Packed'
                    });
                    setIsSending(false);
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        }

    }


    return (
        <div className='bg-white min-h-screen'>
            <HeadOfficeNavBar user={user} />
            <div className='px-6 pt-6 md:px-10 lg:px-40 pb-5 min-h-screen flex flex-col'>
                <div className="py-16 md:py-24 flex-1 flex flex-col">
                    <h2 className="font-bold font-24 md:text-[35px]">Print Details</h2>
                    <div className="min-w-full">
                        <div className="bg-white upload-files-main mt-5 md:mt-10 flex flex-col pb-8 py-5 px-4 md:px-8 overflow-x-auto">
                            <table className="w-full border-collapse">
                                {/* Table Head */}
                                <thead>
                                    <tr className="text-left">
                                        <th className="py-3 px-4 font-semibold text-textBlack">Name</th>
                                        <th className="py-3 px-4 font-semibold text-textBlack">Description</th>
                                        <th className="py-3 px-4 font-semibold text-textBlack">Price</th>
                                        <th className="py-3 px-4 font-semibold text-textBlack">Action</th>
                                    </tr>
                                </thead>

                                {/* Table Body */}
                                <tbody>
                                    {
                                        job.files?.length > 0 ? (
                                            job.files.map((file, index) => (
                                                <tr key={index} className="border-b">
                                                    <td className="py-3 px-4">
                                                        <div className='flex items-center'>
                                                            <img alt="PDF" src={ICONS.pdf} className="h-4 pr-2" />
                                                            <p className='text-darkGrey font-14 font-normal'>{file.fileName}</p>
                                                        </div>
                                                    </td>
                                                    <td className="py-3 px-4">
                                                        <p className='text-darkGrey font-14 font-regular'>
                                                            {Object.entries(file.printOptions)
                                                                .filter(([key, value]) => value !== "" && value !== null && value !== undefined) // Remove empty values
                                                                .map(([key, value]) => {
                                                                    if (key === "quantity") return `X${value}`; // Show "Binding" if it's "Yes"
                                                                    if (key === "binding" && value === "Yes") return "Binding"; // Show "Binding" if it's "Yes"
                                                                    if (key === "doubleSided" && value === "Yes") return "Double Sided"; // Show "Double Binding" if true
                                                                    return value; // Default case
                                                                })
                                                                .join(", ")}

                                                        </p>
                                                    </td>

                                                    <td className="py-3 px-4">
                                                        <p className='text-textBlack font-14 font-bold'>
                                                            R {file.price}
                                                        </p>
                                                    </td>
                                                    <td className="py-3 px-4">
                                                        <a
                                                            className='flex items-center'
                                                            href={file.url}
                                                            download={file.name}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img alt="Download" src={ICONS.download} className='h-6 pl-1 mr-2' />
                                                            <p className='text-[#4688D0] font-14 font-bold'>
                                                                Download
                                                            </p>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : null
                                    }

                                </tbody>
                            </table>

                            <div className='flex items-center justify-between mt-8'>
                                <div className='bg-aliceBlue flex items-center justify-center px-5 py-2 rounded-md'>
                                    <img
                                        src={ICONS.infoBlue}
                                        alt="Icon"
                                        className="w-5 h-5 mr-2" />
                                    <p className='text-appleBlue font-16 font-regular'>
                                        {job.paymentReference ? "Paid Online" : "Paid in Store"}
                                    </p>
                                </div>
                                <h2 className="text-secondary font-20 md:text-[24px] font-semibold mb-3">
                                    Total Price: R {job.totalPrice}
                                </h2>
                            </div>
                            <div className='flex flex-col items-end'>
                                <div className="flex items-center gap-2 mt-4">
                                    <button
                                        onClick={handleSendEmail}
                                        disabled={isSending}
                                        className="text-primary px-4 py-2 rounded-xl border border-primary font-semibold font-14"
                                    >
                                        {isSending ? "Sending..." : "Send Email to User"}
                                    </button>
                                    <button
                                        onClick={handleJobCompleteClick}
                                        className="text-white px-4 py-2 rounded-xl border border-primary bg-primary font-semibold font-14"
                                    >
                                        Mark Job as Completed
                                    </button>
                                </div>
                            </div>
                            {job.custom_request &&
                                <div className='text-md font-medium text-black mt-5 mb-5 text-left'
                                >
                                    <p className='font-bold'>Custom Quote</p>
                                    <p>{job.custom_request}</p>

                                </div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div >
    )
}

export default HeadOfficePrintDetails