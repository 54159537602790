import { useEffect, useState } from 'react';
import HeadOfficeNavBar from '../../components/HeadOfficeNavBar'
import { getDocs, query, collection, where, updateDoc, doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useDashboard } from './DashboardContext';
import MySelector from '../../components/MySelector';


const HeadOfficePastJobs = () => {
    const [user, setUser] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filter, setFilter] = useState("All");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const filterOption1 = ["New", "Old"]
    const filterOption2 = ["This Week", "This Month"]
    const filterOption3 = ["All", "Today"]


    useEffect(() => {
        // Fetch user data from local storage
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);
            setUser(parsedUserData);
        }
    }, []); // Only run once on mount

    useEffect(() => {
        if (user?.id) {
            setLoading(true);
            const q = query(
                collection(db, 'print_requests'),
                where('storeID', '==', user.id)
            );

            // Real-time listener for updates
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const fetchedJobs = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setJobs(fetchedJobs);
                setFilter("All");
                setLoading(false);
            });

            return () => unsubscribe(); // Unsubscribe on unmount
        }
    }, [user]);


    const handleReOpenJob = async (jobId, index) => {
        try {
            // Update the job status in Firestore
            const jobRef = doc(db, 'print_requests', jobId);
            await updateDoc(jobRef, { status: 'Received' });

            // Update the local state to reflect the change
            setJobs(prevJobs => prevJobs.map(job =>
                job.id === jobId ? { ...job, status: 'Received' } : job
            ));
        } catch (error) {
            console.error('Error updating job status:', error);
        }
    };

    // Function to check if a Firestore Timestamp is today in UTC+5 timezone
    const isToday = (createdAt) => {
        if (!createdAt) return false;

        // Ensure createdAt is a Firestore Timestamp and convert to Date
        const createdAtDate = createdAt instanceof Timestamp ? createdAt.toDate() : new Date(createdAt);
        if (isNaN(createdAtDate.getTime())) return false;

        // Convert createdAt to UTC+5 by adding 5 hours
        const createdAtUTC5 = new Date(createdAtDate.getTime() + (5 * 60 * 60 * 1000));

        // Get current date in UTC+5
        const now = new Date();
        const nowUTC5 = new Date(now.getTime() + (5 * 60 * 60 * 1000));

        // Compare year, month, and date to check if it's today
        return (
            createdAtUTC5.getUTCDate() === nowUTC5.getUTCDate() &&
            createdAtUTC5.getUTCMonth() === nowUTC5.getUTCMonth() &&
            createdAtUTC5.getUTCFullYear() === nowUTC5.getUTCFullYear()
        );
    };

    useEffect(() => {
        if (jobs.length > 0) {
            const filtered = jobs
                .filter(job => job.status === 'Closed' || job.status === 'Completed')
                .filter(job =>
                    filter === "All" ||
                    (filter === "Today" && isToday(job.createdAt)) // Use updated isToday function
                )
                .sort((a, b) => {
                    const dateA = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
                    const dateB = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
                    return dateB - dateA; // Sort in descending order
                });

            setFilteredJobs(filtered);
        } else {
            setFilteredJobs([]);
        }
    }, [jobs, filter]);


    return (
        <div className='bg-white min-h-screen'>
            <HeadOfficeNavBar user={user} />
            <div className='px-6 pt-6 md:px-10 lg:px-40 pb-5 min-h-screen flex flex-col'>
                <div className="py-10 sm:py-16 flex-1 flex flex-col">
                    <h2 className='text-black font-bold font-20 md:text-[28px] my-5'>{user?.storeName || "Guest"} </h2>
                    <div className='flex items-center justify-between space-x-6'>
                        <h2 className='text-black font-medium font-16 md:text-[20px]'>Latest Jobs</h2>
                        <div
                            className='flex items-center space-x-2'
                        >
                            <div className='flex item-center flex-1 gap-2'>
                                <div className='min-w-[90px] md:min-w-[120px]'>
                                    <MySelector
                                        options={filterOption1}
                                        placeHolder="New"
                                        handleSaveState={(val) => { }}
                                    />
                                </div>
                                <div className='min-w-[90px] md:min-w-[120px]'>
                                    <MySelector
                                        options={filterOption2}
                                        placeHolder="This Week"
                                        handleSaveState={(val) => { }}
                                    />
                                </div>
                                <div className='min-w-[90px] md:min-w-[120px]'>
                                    <MySelector
                                        options={filterOption3}
                                        placeHolder="All"
                                        handleSaveState={(val) => setFilter(val)}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    {loading ? (
                        // Display loading spinner/message while fetching jobs
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                            <div className="bg-white p-4 rounded-md shadow-lg">
                                <p className="text-lg font-semibold">Loading...</p>
                            </div>
                        </div>
                    ) :
                        filteredJobs.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:gap-6 mt-10">
                                {
                                    filteredJobs.map((job, index) => (
                                        <div key={index} className={'relative p-4 rounded-3xl bg-white dashboard-item-container'}>
                                            <div
                                                className=''
                                            >
                                                <p className="font-18 text-secondary font-bold">{job.customer_email}</p>
                                                {
                                                    job.status === "Closed" ?
                                                        <div
                                                            className='border border-red-500 flex items-center justify-center w-fit px-2 py-1 gap-1 rounded-full mt-2'
                                                        >
                                                            <div
                                                                className='p-1.5 rounded-full bg-red-500'
                                                            />
                                                            <p className="text-red-500 text-[12px] font-normal">Job Closed</p>

                                                        </div>
                                                        : job.status === 'Completed' ?
                                                            <div
                                                                className='border border-primary flex items-center justify-center w-fit px-2 py-1 gap-1 rounded-full mt-2'
                                                            >
                                                                <div
                                                                    className='p-1.5 rounded-full bg-primary'
                                                                />
                                                                <p className="text-primary text-[12px] font-normal">Job Completed</p>

                                                            </div> : null

                                                }


                                            </div>
                                            <div className='horizontal-divider my-5' />
                                            <div className='flex flex-1 items-center justify-between gap-1'>
                                                <div className='flex flex-col flex-1 items-center'>
                                                    <p className="text-darkGrey font-16 font-normal">Needed by</p>
                                                    <p className="text-secondary font-16 font-normal">{job.printNeed}</p>
                                                </div>
                                                <div className='flex flex-col flex-1 items-center'>
                                                    <p className="text-darkGrey font-16 font-normal">Status</p>
                                                    <p className="text-secondary font-16 font-normal">{job.paymentReference ? "Paid Online" : "Paid in Store"}</p>
                                                </div>
                                                <div className='flex flex-col flex-1 items-center'>
                                                    <p className="text-darkGrey font-16 font-normal">Cost</p>
                                                    <p className="text-secondary font-16 font-normal">R {job.totalPrice}</p>
                                                </div>
                                            </div>
                                            <div
                                                className='flex items-center justify-end mt-8 gap-3 mb-4'
                                            >

                                                <button
                                                    type="button"
                                                    onClick={() => handleReOpenJob(job.id)}
                                                    className="flex justify-center rounded-xl border-primary bg-primary px-5 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline "
                                                >
                                                    Reprint Job
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className='flex-1 flex items-center justify-center'>
                                <h2 className='text-black font-bold font-35'>No jobs found</h2>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}

export default HeadOfficePastJobs;
