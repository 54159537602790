import React, { useCallback, useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { ICONS } from '../utils/icons';
import MySelector from '../components/MySelector';
import Footer from '../components/Footer';
import { IMAGES } from '../utils/images';
import { useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib';


const ComparePrices = () => {
    const [stores, setStores] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate(); // Initialize navigate


    const printTypeOptions = ["Normal", "Building Plans"];
    const colorOptions = ["Color", "B/W",];
    const paperSizeOptions = ["A0", "A1", "A2", "A3", "A4"];
    const paperThicknessOptions = ["80 GSM: Normal", "90 GSM", "100 GSM"];

    const [printType, setPrintType] = useState('');
    const [paperSize, setPaperSize] = useState('');
    const [color, setColor] = useState('');
    const [paperThickness, setPaperThickness] = useState('');
    const [quantity, setQuantity] = useState(1);


    const getAllStores = async () => {
        try {
            const usersCollection = collection(db, "users");
            const usersSnapshot = await getDocs(usersCollection);
            const usersList = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                totalPrice: 0, // Initialize total price
            }));
            setStores(usersList);
        } catch (error) {
            console.error("Error fetching users: ", error);
        }
    }

    useEffect(() => {
        getAllStores();
    }, []);

    const handleRemoveFile = (index) => {
        setFileList(prevFileList => prevFileList.filter((_, i) => i !== index));
        setIsModalOpen(false);
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    // Function to extract page count from the PDF
    const getPdfPageCount = async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        return pdfDoc.getPageCount();
    };


    const handleFileInput = async (event) => {
        const files = event.target.files;
        const validFileTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'image/jpeg', // JPEG image
            'image/png', // PNG image
        ];

        // Filter files to check for Excel files in advance
        const excelFiles = Array.from(files).filter(file =>
            file.type.includes("sheet") || file.type.includes("excel")
        );

        // Alert if multiple Excel files are selected
        if (excelFiles.length > 1) {
            alert("You can only upload one Excel file at a time.");
            return;
        }


        const selectedFiles = await Promise.all(
            Array.from(files).map(async (file) => {
                if (!validFileTypes.includes(file.type)) return null; // Ignore unsupported files

                let pageCount = 1; // Default page count

                if (file.type === 'application/pdf') {
                    pageCount = await getPdfPageCount(file); // Get actual PDF page count
                }
                else if (file.type.includes("sheet") || file.type.includes("excel")) {
                    pageCount = prompt("Enter the number of pages you want to print for this Excel file. Tip: Save the Excel file as a PDF and check how many pages are produced.");
                    if (!pageCount || isNaN(pageCount) || pageCount <= 0) {
                        alert("Invalid page count. Please enter a valid number.");
                        return null;
                    }
                    pageCount = parseInt(pageCount);
                }
                else if (file.type.startsWith('image/')) {
                    pageCount = 1; // Images count as 1 page
                }

                return {
                    file,
                    fileName: file.name,
                    fileType: file.type,
                    pageCount,
                };
            })
        );

        // Filter out null results and set fileList
        const filteredFiles = selectedFiles.filter(file => file !== null);
        setFileList(filteredFiles); // Now fileList includes both file and pageCount
    };


    const handlePrintNow = (user) => {
        const storeName = user.storeName ? encodeURIComponent(user.storeName) : "";
        navigate(`/?storename=${storeName}`);
    };

    // Calculate price for each store
    const calculatePrice = useCallback(() => {
        setStores(prevStores => {
            return prevStores.map(store => {
                const isBuildingPlans = printType === "Building Plans";
                const pricingDataToUse = isBuildingPlans ? store.buildingPlanPricing : store.pricing;

                if (!pricingDataToUse || pricingDataToUse.length === 0) return { ...store, totalPrice: 0 };

                let filePrice = 0;
                fileList.forEach(file => {
                    const { pageCount } = file;
                    let pricing = null;


                    if (isBuildingPlans) {
                        pricing = pricingDataToUse.find(item => item.type === color);
                        if (pricing) {
                            const sizePricing = pricing.sizes.find(sizeItem => sizeItem.size === paperSize);
                            if (sizePricing) {
                                filePrice += sizePricing.price * pageCount * quantity;
                            }
                        }
                    } else {
                        pricing = pricingDataToUse.find(
                            item => item.paperSize === paperSize && item.color === color
                        );

                        if (pricing) {
                            const priceRange = pricing.priceRanges.find(range => {
                                const [minPages, maxPages] = range.pages.split("-").map(str => parseInt(str.replace(/\D/g, ""), 10));
                                return pageCount >= minPages && (maxPages ? pageCount <= maxPages : true);
                            });

                            if (priceRange) {
                                filePrice += priceRange.price * pageCount * quantity;
                            }
                        }
                    }
                });

                return { ...store, totalPrice: filePrice };
            });
        });
        handleOpenModal();
    }, [fileList, paperSize, color, quantity, printType]);

    return (
        <div>
            <NavBar />
            <div className="relative isolate px-4 pt-8 md:px-10 lg:px-40">
                <div className="py-12 sm:py-16">
                </div>
            </div>
            <div className='px-4 md:px-5 lg:px-40 pb-5'>
                <div>
                    <h2 className='text-secondary font-bold font-28 md:text-3xltext-left'>Compare Prices</h2>
                    {fileList.length > 0 ?
                        <div className='justify-items-center'>
                            <div className='compare-prices-upload-container rounded-3xl px-5 py-3 mt-5 w-full'>
                                <div className="overflow-x-auto">
                                    <div className="flex flex-col md:flex-row gap-4">
                                        {fileList.map((file, index) => (
                                            <div key={index} className={'print-details-item mt-3 mb-3 ml-1 min-w-[300px]'}>
                                                <div className='flex items-center justify-between'>
                                                    <div className='flex items-center'>
                                                        <img alt="PDF" src={ICONS.pdf} className="h-5 pr-2" />
                                                        <p className={'text-darkGrey font-16 font-normal'}>{file.fileName}</p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        {/* <button
                                                            onClick={() => { }}
                                                        >
                                                            <img alt="Edit" src={ICONS.edit} className={'h-5 pl-1 mr-2 brightness-100'} />
                                                        </button> */}
                                                        <button
                                                            onClick={() => handleRemoveFile(index)}
                                                        >
                                                            <img alt="Delete" src={ICONS.delete} className={'h-5 pl-1 mr-2 pr-1 grayscale-0'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <button
                                onClick={() => document.getElementById("fileInput").click()}
                                className='border border-borderColor text-darkGrey rounded-xl py-1.5 px-5 font-20 font-bold mt-10'
                            >
                                Upload more Files
                            </button>
                            <input
                                type="file"
                                id="fileInput"
                                multiple
                                accept=".pdf,.xlsx,.xls,image/*" // Accept PDF, Excel, and image files
                                onChange={handleFileInput}
                                style={{ display: 'none' }}
                            /> */}
                        </div>
                        :
                        <div className='flex flex-col items-center justify-center compare-prices-upload-container rounded-2xl py-10 mt-5 px-4'>
                            <img
                                src={ICONS.cloudAdd}
                                className='w-10 h-10'
                                alt='Icon' />
                            <h2 className='text-secondary font-bold font-20 md:text-xl mt-5'>Choose a file or drag & drop it here</h2>
                            <p className='text-darkGrey font-medium font-16 mt-1'>JPEG, PNG and PDf files</p>
                            <button
                                onClick={() => document.getElementById("fileInput").click()}
                                className='border border-borderColor text-darkGrey rounded-xl py-1.5 px-10 font-20 font-bold mt-10'
                            >
                                Browse File
                            </button>
                            <input
                                type="file"
                                id="fileInput"
                                accept=".pdf,.xlsx,.xls,image/*" // Accept PDF, Excel, and image files
                                onChange={handleFileInput}
                                style={{ display: 'none' }}
                            />
                        </div>
                    }
                    <div className='flex-1 items-center'>
                        <h2 className='text-secondary font-bold font-24 text-left py-5 mt-5'>Printing Details</h2>
                        <div className='flex flex-col md:flex-row md:items-center gap-3 mt-3'>
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Number of Copies</p>
                                <input
                                    name="quantity"
                                    id="quantity"
                                    type="number"
                                    min={1}
                                    value={quantity}
                                    placeHolder="Enter Number of Copies"
                                    onChange={(event) => setQuantity(event.target.value)}
                                    className={`text-textBlack border border-borderColor placeholder-lightGrey px-4 py-2 rounded-xl outline-none font-16 w-full`}
                                />

                            </div>
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Color or Black & White</p>
                                <MySelector
                                    options={colorOptions}
                                    placeHolder="Select an Option"
                                    handleSaveState={(val) => setColor(val)}
                                />

                            </div>
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Paper Size</p>
                                <MySelector
                                    options={paperSizeOptions}
                                    placeHolder="Select Paper Size"
                                    handleSaveState={(val) => setPaperSize(val)}
                                />
                            </div>

                        </div>
                        <div className='flex flex-col md:flex-row md:items-center gap-3 mt-5'>
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Paper Weight</p>
                                <MySelector
                                    options={paperThicknessOptions}
                                    placeHolder="Select an Option"
                                    handleSaveState={(val) => setPaperThickness(val)}
                                />

                            </div>
                            <div className='flex-1'>
                                <p className='text-textBlack font-medium font-14 mb-3'>Print Type</p>
                                <MySelector
                                    options={printTypeOptions}
                                    placeHolder="Select Print Type"
                                    handleSaveState={(val) => setPrintType(val)}
                                />
                            </div>
                        </div>
                        <div className='flex items-center justify-center mt-10 mb-5'>
                            <button
                                className="flex font-semibold font-16 rounded-xl items-center justify-center bg-primary text-white py-3 px-5"
                                onClick={calculatePrice}
                            >
                                Compare Store Prices
                            </button>
                        </div>
                    </div>
                </div>

                {stores.length > 0 && (
                    isModalOpen &&
                    <>
                        <h2 className="font-18 md:text-xl font-bold mb-3">Different Store Prices</h2>
                        {stores.map((user, index) => (
                            <div
                                className="min-w-full flex items-center justify-between mt-3 stores-item flex-col lg:flex-row"
                                key={index}
                            >
                                <div className="flex flex-col items-center md:flex-row">
                                    <img
                                        src={IMAGES.stores}
                                        className="w-44 h-44 md:w-36 md:h-36 rounded-2xl"
                                        alt="Stores"
                                    />
                                    <div className="flex flex-col pl-3 pr-3 pt-1 pb-1 mt-3 md:mt-0">
                                        <h2 className="font-medium font-20 text-left text-secondary">
                                            {user.storeName}
                                        </h2>

                                        <div className="flex items-center w-full mt-5 my-3 space-x-2">
                                            <img
                                                src={ICONS.location}
                                                className="w-5 h-5"
                                                alt="icon"
                                            />
                                            <p className="text-left text-darkGrey font-normal">
                                                {user.address}
                                            </p>
                                        </div>
                                        <div className="flex items-center w-full space-x-2">
                                            <img
                                                src={ICONS.phone}
                                                className="w-5 h-5"
                                                alt="icon"
                                            />
                                            <p className="text-left text-darkGrey font-normal">
                                                {user.phoneNumber}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4 mt-5 lg:mt-0">
                                    <h2 className="font-bold font-18 text-primary">
                                        R{user.totalPrice}
                                    </h2>
                                    <button
                                        className="flex font-semibold font-16 rounded-xl items-center justify-center bg-primary text-white pt-2 pb-2 pl-5 pr-5"
                                        onClick={() => handlePrintNow(user)}
                                    >
                                        Visit Shop
                                    </button>
                                </div>
                            </div>
                        ))}
                    </>
                )}



                {/* {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 lg:px-20" style={{ zIndex: 999 }}>
                        <div className="bg-white p-5 md:p-10 rounded-3xl my-16 max-h-[800px] overflow-auto" style={{ width: '90%' }}>
                            <div className='flex items-center justify-between'>
                                <h2 className="font-18 md:text-xl font-bold mb-3">Different Store Prices</h2>
                                <button
                                    onClick={handleCloseModal}
                                    className="px-2"
                                >
                                    <img
                                        src={ICONS.close}
                                        className='w-4 h-4'
                                        alt='Close'
                                    />
                                </button>
                            </div>
                            {
                                stores.length > 0 &&
                                stores.map((user, index) => (
                                    <div className="min-w-full flex items-center justify-between mt-3 stores-item flex-col lg:flex-row" key={index}>
                                        <div className="flex flex-col items-center md:flex-row">
                                            <img
                                                src={IMAGES.stores}
                                                className="w-44 h-44 md:w-36 md:h-36 rounded-2xl"
                                                alt="Stores"
                                            />
                                            <div className="flex flex-col pl-3 pr-3 pt-1 pb-1 mt-3 md:mt-0">
                                                <h2 className="font-medium font-20 text-left text-secondary">
                                                    {user.storeName}
                                                </h2>


                                                <div className="flex items-center w-full mt-5 my-3 space-x-2">
                                                    <img
                                                        src={ICONS.location}
                                                        className="w-5 h-5"
                                                        alt="icon"
                                                    />
                                                    <p className="text-left text-darkGrey font-normal">
                                                        {user.address}
                                                    </p>
                                                </div>
                                                <div className="flex items-center w-full space-x-2">
                                                    <img
                                                        src={ICONS.phone}
                                                        className="w-5 h-5"
                                                        alt="icon"
                                                    />
                                                    <p className="text-left text-darkGrey font-normal">
                                                        {user.phoneNumber}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='flex items-center gap-4 mt-5 lg:mt-0'
                                        >
                                            <h2 className="font-bold font-18 text-primary">
                                                {user.totalPrice}
                                            </h2>
                                            <button
                                                className="flex font-semibold font-16 rounded-xl items-center justify-center bg-primary text-white pt-2 pb-2 pl-5 pr-5"
                                                onClick={() => handlePrintNow(user)}
                                            >
                                                Visit Shop
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )} */}
            </div>
            <Footer />
        </div>
    )
}

export default ComparePrices;