import React, { useEffect, useState, useRef } from "react";
import "../css/myselector.css";
import { ICONS } from "../utils/icons";

const MySelector = ({ options, handleSaveState, error, placeHolder, disabledOptions = [], defaultOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (defaultOption) {
            setSelectedOption(defaultOption);
        }
    }, [defaultOption]);

    useEffect(() => {
        // Ensure handleSaveState is triggered if there's an initial selection
        handleSaveState(selectedOption);
    }, [selectedOption]); // Runs when selectedOption changes

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        if (!disabledOptions.includes(option)) {
            setSelectedOption(option);
            handleSaveState(option);
            setIsOpen(false);
        }
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="my-selector-wrapper" ref={dropdownRef}>
            <div className={`my-selector-container ${error ? "border-danger" : ""}`}>
                <div className="my-selector-box" onClick={toggleDropdown}>
                    <div className="my-selector-text">{selectedOption || placeHolder}</div>
                    <img alt="Icon" src={ICONS.arrowDown} className="w-5 h-5" />
                </div>
            </div>
            {isOpen && (
                <div className="my-dropdown-list">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`my-dropdown-item ${selectedOption === option ? 'active' : ''} ${disabledOptions.includes(option) ? 'disabled' : ''}`}
                            onClick={() => selectOption(option)}
                        >
                            {option}
                        </div>
                    ))}

                </div>
            )}
        </div>
    );
};

export default MySelector;
