import React, { useEffect, useState, useRef } from "react";
import "../css/mymultiselector.css";
import { ICONS } from "../utils/icons";

const MyMultiSelector = ({ options, handleSaveState, error, placeHolder, disabledOptions = [], defaultOptions = [] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]); // Holds multiple selected values
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (defaultOptions.length > 0) {
            setSelectedOptions(defaultOptions);
        }
    }, [defaultOptions]);

    useEffect(() => {
        handleSaveState(selectedOptions);
    }, [selectedOptions]); // Save state when options change

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        if (!disabledOptions.includes(option)) {
            setSelectedOptions((prev) =>
                prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
            );
        }
    };

    const removeOption = (option) => {
        setSelectedOptions((prev) => prev.filter((item) => item !== option));
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="my-selector-wrapper" ref={dropdownRef}>
            <div className={`my-selector-container ${error ? "border-danger" : ""}`}>
                <div className="my-selector-box" onClick={toggleDropdown}>
                    <div className="my-selected-options">
                        {selectedOptions.length > 0 ? (
                            selectedOptions.map((option, index) => (
                                <div className="flex my-selected-item-box">
                                    <span key={index} className="my-selected-item">
                                        {option}
                                    </span>
                                    <img
                                        src={ICONS.close}
                                        className="remove-item w-2 h-2"
                                        alt="Icon"
                                        onClick={() => removeOption(option)}
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="my-selector-text">{placeHolder}</div>
                        )}
                    </div>
                    <img alt="Icon" src={ICONS.arrowDown} className="w-5 h-5" />
                </div>
            </div>
            {isOpen && (
                <div className="my-dropdown-list">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`my-dropdown-item ${selectedOptions.includes(option) ? 'active' : ''} ${disabledOptions.includes(option) ? 'disabled' : ''}`}
                            onClick={() => selectOption(option)}
                        >
                            {option} {selectedOptions.includes(option) && "✔"}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MyMultiSelector;
