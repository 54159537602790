import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import L from "leaflet"; // Import Leaflet library
import { IMAGES } from "../utils/images";
import { ICONS } from "../utils/icons";
import Footer from "../components/Footer";


const Stores = () => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate(); // Initialize navigate


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, "users");
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = usersSnapshot.docs.map(doc => doc.data());
                setUsers(usersList);
            } catch (error) {
                console.error("Error fetching users: ", error);
            }
        };

        fetchUsers();
    }, []);

    // Initialize Leaflet map
    useEffect(() => {
        let map; // Declare the map variable

        // Check if map is already initialized, to prevent reinitialization
        if (!map) {
            map = L.map('map').setView([-33.9321, 18.8602], 13);

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            L.marker([-33.9321, 18.8602]).addTo(map)
                .bindPopup('The Office Crew')
                .openPopup();
        }

        // Cleanup function to remove the map instance when the component unmounts
        return () => {
            if (map) {
                map.remove();
            }
        };
    }, []); // Ensure this effect only runs once when the component mounts


    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const handlePrintNow = (user) => {
        const storeName = user.storeName ? encodeURIComponent(user.storeName) : "";
        navigate(`/?storename=${storeName}`);
    };


    const filteredUsers = users.filter(user =>
        user.storeName.toLowerCase().includes(searchQuery) ||
        user.address.toLowerCase().includes(searchQuery)
    );

    return (
        <div className="bg-white">
            <NavBar />
            <div className="relative isolate px-4 pt-8 md:px-10 lg:px-40">
                <div className="py-12 sm:py-20 flex items-center justify-center mt-4">
                    <div className="relative w-full lg:w-1/2 border border-darkGrey rounded-full">
                        <input
                            name="search"
                            type="text"
                            id="search"
                            placeholder="Search for Printing stores/ areas"
                            className="block text-md lg:text-lg py-1.5 pl-5 pr-12 placeholder:text-darkGrey sm:leading-6 w-full outline-none rounded-full"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                        <img
                            src={ICONS.searchPrimary}
                            alt="Search Icon"
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 w-5 h-5"
                        />
                    </div>
                </div>

            </div>
            <div className="flex flex-col h-full px-4 md:px-10 lg:px-40">
                <h2 className="font-bold font-24 md:text-3xl text-left mb-8">
                    {searchQuery ?
                        filteredUsers.length > 0
                        && filteredUsers.length
                        : "All"
                    } Results based on your search</h2>
                {filteredUsers.map((user, index) => (
                    <div className="min-w-full flex items-center justify-between mt-3 stores-item" key={index}>
                        <div className="flex">
                            <img
                                src={IMAGES.stores}
                                className="w-36 h-36 rounded-2xl"
                                alt="Stores"
                            />
                            <div className="flex flex-col pl-3 pr-3 pt-1 pb-1">
                                <h2 className="font-medium font-16 md:text-lg text-left text-secondary">
                                    {user.storeName}
                                </h2>
                                <div className="flex items-center w-full">
                                    <div className="flex items-center space-x-1">
                                        <span className="text-orange">★</span>
                                        <span className="text-orange">★</span>
                                        <span className="text-orange">★</span>
                                        <span className="text-orange">★</span>
                                        <span className="text-borderColor">★</span>
                                    </div>
                                    <p className="text-left text-darkGrey font-normal ml-3 text-xs md:text-base">
                                        <strong>4.0</strong>  (20 Reviews)
                                    </p>
                                </div>

                                <div className="flex items-center w-full mt-5 my-3 space-x-2">
                                    <img
                                        src={ICONS.location}
                                        className="w-5 h-5"
                                        alt="icon"
                                    />
                                    <p className="text-left text-darkGrey font-normal text-sm md:text-base">
                                        {user.address}
                                    </p>
                                </div>
                                <div className="flex items-center w-full space-x-2">
                                    <img
                                        src={ICONS.phone}
                                        className="w-5 h-5"
                                        alt="icon"
                                    />
                                    <p className="text-left text-darkGrey font-normal text-sm md:text-base">
                                        {user.phoneNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button
                            className="flex font-semibold text-sm md:text-base rounded-xl items-center justify-center bg-primary text-white py-2 px-3 md:px-5"
                            onClick={() => handlePrintNow(user)}
                        >
                            Visit Shop
                        </button>
                    </div>
                ))}

                <div className="mt-5 mb-8">
                    <h2 className="font-bold font-24 md:text-3xl text-center mb-8 mt-8">
                        Search on Map
                    </h2>

                    {/* Leaflet Map */}
                    <div
                        id="map"
                        className="rounded-2xl"
                        style={{ height: "400px" }}>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Stores;
