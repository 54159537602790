import React, { useEffect } from 'react';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { ICONS } from '../utils/icons';
import Footer from '../components/Footer';


const Pricing = () => {

    // Define the plans with their respective plan codes
    const plans = [
        { name: 'Basic', price: 299, description: ['Basic features will be included to log in and sale..'], features: ['Basic Print Jobs', 'Email Support'], planCode: 'PLN_ue3cdzzdt5s7ds1' },
        { name: 'Standard', price: 599, description: ['Advance features will be included to log in and sale..'], features: ['Unlimited Print Jobs', 'Customizable Print Jobs', 'Email Support'], planCode: 'PLN_ml0cxtwht9bkwws' },
        { name: 'Enterprise', price: 949, description: ['Advance and unlimited features will be included to log in and sale..'], features: ['Unlimited Print Jobs', 'Customizable Print Jobs', 'Email Support', 'Key Metric Tracking'], planCode: 'PLN_mlziwjtoort71o1' },
    ];

    const livekey = "sk_live_175c4cb1de422b556b365c7c33554ed5349d5d89";


    const subaccount = 'ACCT_y1bc6aimcpyeoop';

    const initializeTransaction = async (plan) => {
        try {
            const response = await fetch('https://api.paystack.co/transaction/initialize', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${livekey}` // Replace with your Paystack secret key
                },
                body: JSON.stringify({
                    email: 'customer@example.com', // Replace with actual customer email
                    amount: plan.price * 100, // Paystack requires the amount in kobo (Naira)
                    subaccount,
                    plan: plan.planCode,
                    callback_url: 'https://signetprint.co.za/verify-payment',
                })
            });

            const data = await response.json();
            if (data.status) {
                // Redirect to Paystack payment link
                window.location.href = data.data.authorization_url;
            } else {
                console.error('Transaction initialization failed:', data.message);
            }
        } catch (error) {
            console.error('Error initializing transaction:', error);
        }
    };


    const handleSignUpNowClick = (plan) => {
        initializeTransaction(plan);
    };



    return (
        <div className='min-h-screen'>
            <div className="flex flex-col py-10 px-4 md:px-10 lg:px-40 mb-5">
                <div className='mb-10'>
                    <NavBar />
                </div>

                <div className="mb-10 mt-14">
                    <h2 className="text-textBlack font-28 font-bold mb-1">
                        List your shop
                    </h2>
                    <h2 className="text-textBlack font-20 font-bold">
                        Select Subscription Option
                    </h2>
                </div>

                <div className="relative border border-primary bg-[#F9F9F9] rounded-[3rem] p-14">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {plans.map((plan, index) => {
                            const isLastPlan = index === plans.length - 1;

                            return (
                                <div
                                    key={index}
                                    className={`flex flex-col p-6 w-full rounded-3xl transition-all duration-300 ${isLastPlan
                                        ? "bg-primary text-white p-12 shadow-xl scale-110 max-w-lg mt-4 lg:-mt-16"
                                        : "text-secondary"
                                        }`}
                                >
                                    {isLastPlan && (
                                        <span className="absolute top-4 right-4 bg-white text-primary text-sm font-semibold px-4 py-1 rounded-full">
                                            Most Popular
                                        </span>
                                    )}

                                    <p className={`mb-4 font-bold text-[46px] ${isLastPlan ? "text-white" : "text-secondary"}`}>
                                        R{plan.price}
                                        <span className="font-normal text-[20px]"> /month</span>
                                    </p>
                                    <h2 className={`font-bold text-[34px] ${isLastPlan ? "text-white" : "text-secondary"}`}>
                                        {plan.name}
                                    </h2>
                                    <p className={`font-16 font-medium ${isLastPlan ? "text-white/80" : "text-darkGrey"}`}>
                                        {plan.description}
                                    </p>

                                    <div className="mt-5 mb-5 flex flex-col space-y-2">
                                        {plan.features.map((feature) => (
                                            <div key={feature} className="flex items-center gap-3">
                                                <img src={ICONS.checkCircle} className="w-6 h-6" />
                                                <p className={`font-18 font-medium ${isLastPlan ? "text-white" : "text-darkGrey"}`}>
                                                    {feature}
                                                </p>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="flex flex-1 justify-center items-end mt-8">
                                        <button
                                            className={`block w-full py-3.5 rounded-full text-center font-semibold font-16 ${isLastPlan ? "bg-white text-primary" : "btn-primary text-white"
                                                }`}
                                            onClick={() => handleSignUpNowClick(plan)}
                                        >
                                            Choose plan
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>


            <Footer />


        </div>
    );
};

export default Pricing;