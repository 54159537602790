import React, { useEffect, useState } from "react";
import "../css/mytogglebutton.css";

const MyToggleButton = ({ type, handleSaveState, defaultSelection, disableYes = false, error }) => {
    const [selected, setSelected] = useState(defaultSelection);

    useEffect(() => {
        if (defaultSelection) {
            if (type === 'Color') {
                setSelected(defaultSelection === 'Color' ? 'Yes' : 'No');
            } else {
                setSelected(defaultSelection);
            }
        }
    }, [defaultSelection]);

    useEffect(() => {
        if (type === 'Color') {
            handleSaveState(selected === 'Yes' ? 'Color' : 'B/W');
        }
    }, [])

    const handleSelection = (value) => {
        if (value === 'Yes' && disableYes) return;
        setSelected(value);
        if (type === 'Color') {
            handleSaveState(value === 'Yes' ? 'Color' : 'B/W');
        } else {
            handleSaveState(value === 'Yes' ? 'Yes' : 'No');
        }
    };

    return (
        <div className="my-custom-toggle-button-container">
            <div className="my-button-group">
                <button
                    className="my-toggle-button gap-2"
                    onClick={() => handleSelection("Yes")}
                    disabled={disableYes}
                >
                    <div
                        className={`radio-circle ${selected === "Yes" ? "my-selected" : ""} ${disableYes ? "disabled" : ""} ${error ? "border-danger" : ""}`}
                    />
                    Yes
                </button>
                <button
                    className="my-toggle-button gap-2"
                    onClick={() => handleSelection("No")}
                >
                    <div
                        className={`radio-circle ${selected === "No" ? "my-selected" : ""} ${error ? "border-danger" : ""}`}
                    />
                    No
                </button>
            </div>
        </div>
    );
};

export default MyToggleButton;
