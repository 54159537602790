import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IMAGES } from '../utils/images';
import { ICONS } from '../utils/icons';
import { auth } from '../firebase/firebaseConfig'; // Import the auth instance
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const navigation = [
    { name: 'Home', href: '/admin/dashboard' },
    { name: 'Prices', href: '/admin/my-pricing' },
    { name: 'Analytics', href: '/admin/analytics' },
    { name: 'Past Jobs', href: '/admin/closed-jobs' },
    { name: 'New Service', href: '/admin/add-new-service' },
    { name: 'Logout', href: '#' }, // We'll handle logout via onClick
];

const HeadOfficeNavBar = ({ user }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();


    const handleLogout = async () => {
        try {
            await signOut(auth); // Sign out the user
            localStorage.removeItem('user');
            navigate('/', { replace: true }); // Redirect to the home page or login page
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };



    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav aria-label="Global" className="flex items-center justify-between p-6 md:px-10 lg:px-40">
                <div className="flex">
                    <a href="/admin/dashboard" className="-m-1.5 p-1.5 flex items-center space-x-2"
                    >
                        <img
                            alt=""
                            src={IMAGES.logoGreen}
                            className="h-8"
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                    >
                        <img
                            alt="Open main menu"
                            src={ICONS.menu}
                            className="h-8 filter grayscale contrast-200"
                        />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-6 lg:flex-1 lg:justify-center">
                    {navigation.map((item) => (
                        item.name === 'Logout' ? (
                            <button
                                key={item.name}
                                onClick={handleLogout}
                                className="font-18 font-medium text-darkGrey"
                            >
                                {item.name}
                            </button>
                        ) :
                            (
                                <a key={item.name} href={item.href} className="font-18 font-medium text-darkGrey">
                                    {item.name}
                                </a>
                            )
                    ))}
                </div>
                <div className='hidden lg:flex lg:gap-4 lg:items-center'>
                    <button
                        className=''
                    >
                        <img
                            alt="Notification"
                            src={ICONS.bell}
                            className="h-6"
                        />
                    </button>
                    <button
                        className='flex items-center gap-3'
                    >
                        <img
                            alt="Avatar"
                            src={IMAGES.avatar}
                            className="h-10"
                        />
                        <button
                            className="font-16 font-medium text-darkGrey"
                        >
                            {user?.storeName || "Guest"}
                        </button>
                    </button>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="lg:hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5 flex items-center space-x-2">
                            <img
                                alt=""
                                src={IMAGES.logoGreen}
                                className="h-6"
                            />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    item.name === 'Logout' ? (
                                        <button
                                            key={item.name}
                                            onClick={handleLogout}
                                            className="-mx-3 block w-full text-left rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </button>
                                    ) : (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    )
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
};

export default HeadOfficeNavBar;
